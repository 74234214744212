import React from 'react'
import styles from './big-img-service.module.scss';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import { GoArrowRight } from "react-icons/go";
import {  home_service_list_bg_img_1 } from '../../assets/images';


const BigImgService = ({ BigImgServiceData, refTarget }) => {
    return (
        <div className={styles.hmg_service_item} id={BigImgServiceData?.pId} ref={refTarget}>
            <Container>
                <ul>
                { BigImgServiceData?.bgLogo && <img src={home_service_list_bg_img_1} alt="logo" className={styles.hmg_service_middle_logo} />}
                    <li data-aos="zoom-in" data-aos-duration="2000" className={BigImgServiceData.orderImgSec ? BigImgServiceData.orderImgSec : "order-1"}>
                        <img src={BigImgServiceData?.imgSrc} alt={BigImgServiceData?.alt} className="img-fluid w-100" />
                    </li>
                    <li data-aos="fade-up" data-aos-duration="3000" className={BigImgServiceData.orderContentSec ? BigImgServiceData.orderContentSec : "order-2"}>
                        <div className={`${styles["lft_content_sec"]} ${BigImgServiceData.customPadding ? BigImgServiceData.customPadding : "pe-0"}`}>
                            <div className="sub-heading">
                                <h2>{BigImgServiceData?.heading}</h2>
                                <p>{BigImgServiceData?.desc}</p>
                                <Link to={BigImgServiceData.links} className={`${styles["read_more_text"]}`}>
                                    Read More
                                </Link>
                            </div>
                        </div>
                    </li>
                    {/* <img src={home_service_list_bg_img_1} alt="logo" className={styles.hmg_service_middle_logo} /> */}
                </ul>
            </Container>
        </div>

    )
}

export default BigImgService;