import React from "react";
import styles from "./cta.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import ModelForm from "./ModelPopup";
import { BlackLogo } from "../../assets/images";

const Cta = () => {
  const modelFormData = {
    btnName: "Contact Us Today"
  }
  return (
    <section className={styles.hmg_cta}>
      <Container className={styles.hmg_cta_content}>
        <Row className="justify-content-center">
          <Col lg="7">
            <div className="sub-heading text-center">
              <h2><span>Bring Your Dream </span>Project To Life: From Blueprints
                To Reality!
              </h2>
              <div className={styles.btn_sec}>
                <ModelForm modelFormData={modelFormData} />
              </div>
            </div>
          </Col>
        </Row>
        <span className={styles.denver_text}>DENVER</span>
        <img src={BlackLogo} className={styles.ddb_logo} alt="DDb" />
      </Container>
    </section>
  );
};

export default Cta;
