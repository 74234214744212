import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import styles from "./home-banner.module.scss";
import { hmg_banner_img } from "../../assets/images";
import bannerVideo from '../../assets/videos/slider_video_1.mp4'
import GoBottom from "./GoBottom";

const HomeBanner = ({scrollToElement}) => {
  // const images = [hmg_banner_img, hmg_banner_img, hmg_banner_img]

  return (
    <section className={`hmg-banner ${styles.hmg_banner}`}>
      <video
        className={styles.landing_video}
        src={bannerVideo}
        muted
        poster={hmg_banner_img}
        autoPlay={"autoplay"}
        loop
      >video tag is not supported by your browser
      </video>
      <div className={styles.slider_one}>
        <Container className="container-lg">
          <Row>
            <Col lg="8">
              <div className={styles.content}>
                <h1>Your Vision, Our Team: Endless Possibilities</h1>
                <p>Transform your vision into reality with our exclusive home design services.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <GoBottom scrollToElement={scrollToElement} />
    </section>
  );
};

export default HomeBanner;
