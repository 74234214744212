import React, { useRef } from "react";
import HeadMetaSec from "../components/HeadMetaSec";
import { PrivacyData } from "../components/privacy-policy/PrivacyData";
import Testimonials from "../components/globalComponent/Testimonials";
import PrivacyContentSec from "../components/privacy-policy/PrivacyContentSec";
import PrivacyBanner from "../components/privacy-policy/PrivacyBanner";

const Home = () => {
    const scrollToElement = useRef(null)
    return (
        <>
            <HeadMetaSec HeadSecData={PrivacyData.helmetSec} />
            <PrivacyBanner scrollToElement = {scrollToElement} BannerData={PrivacyData.bannerData} />
            <PrivacyContentSec/>
            <Testimonials/>
        </>
    )
}

export default Home