import React, { useRef } from "react";
import HeadMetaSec from "../components/HeadMetaSec";
import { PortfolioPageData } from "../components/Portfolio/PortfolioData";
import Testimonials from "../components/globalComponent/Testimonials";
import PortfolioContentSec from "../components/Portfolio/PortfolioContentSec";
import PortfoliotBanner from "../components/Portfolio/PortfoliotBanner";

const Home = () => {
    const scrollToElement = useRef(null)
    return (
        <>
            <HeadMetaSec HeadSecData={PortfolioPageData.helmetSec} />
            <PortfoliotBanner className="contact-banner" scrollToElement={scrollToElement} />
            <PortfolioContentSec />
            <Testimonials />
        </>
    )
}

export default Home