
import "bootstrap/dist/css/bootstrap.min.css";
import './App.scss';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import MainRoutes from './_routes/MainRoutes';

function App() {
  const router = createBrowserRouter([MainRoutes]);
  return (
    <RouterProvider router = {router}/>
  );
}

export default App;
