import React, { useEffect } from "react";
import notFoundImg from '../assets/images/not-found.svg'
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";


const NotFound = () => {
    useEffect(() => {
        document.body.classList.add('not-found-body')
        return () => document.body.classList.remove('not-found-body')
    },[])
    return (
        <section className="not-found">
            <Container>
                <div className="text-center">
                    <img src={notFoundImg} alt="not found" className="img-fluid" />
                    <div>
                        <h1>PAGE NOT FOUND</h1>
                        <p>
                            Look like something went wrong! The page you were
                            <span className="d-md-block">looking for is not here.<Link to="/"> Go Home</Link> or <Link to="/contact-us">Contact us</Link>.</span>
                        </p>
                    </div>
                </div>
            </Container>
        </section>

    )
}

export default NotFound;