import { BiMobileAlt, BiUser, BiMailSend } from "react-icons/bi";
import styles from "./../Home/ready-bring-sec.module.scss";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { BeatLoader } from "react-spinners";

const ContactForm = ({ validated, FormSubmit, onChange, validatePhone, phone, loading }) => {
    return (
        <Form
            className={`${styles.ready_bring_frm} `}
            noValidate
            validated={validated}
            onSubmit={FormSubmit}
        >
            <Row>
                <Col lg="9">
                    <Row>
                        <Col
                            lg="4"
                            md="4"
                            sm="6"
                            className={styles.frm_grp}
                            data-aos="fade-right"
                            data-aos-duration="3000"
                        >
                            <Form.Group>
                                <InputGroup hasValidation>
                                    <i>
                                        <BiUser />
                                    </i>
                                    <Form.Control
                                        type="text"
                                        placeholder="Your Name"
                                        className={styles.frm_control}
                                        required
                                        name="fname"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Your Name.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col
                            lg="4"
                            md="4"
                            sm="6"
                            className={styles.frm_grp}
                            data-aos="fade-right"
                            data-aos-duration="3000"
                        >
                            <Form.Group>
                                <InputGroup hasValidation>
                                    <i>
                                        <BiMailSend/>
                                    </i>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        className={styles.frm_control}
                                        required
                                        name="email"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter a Valid Email Address.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col
                            lg="4"
                            md="4"
                            className={styles.frm_grp}
                            data-aos="zoom-in"
                            data-aos-duration="3000"
                        >
                            <div className={styles.phone_number}>
                                <i>
                                    <BiMobileAlt />
                                </i>
                                <PhoneInput
                                    maxLength={15}
                                    placeholder="Phone Number"
                                    required={true}
                                    name="phone"
                                    value={phone}
                                    onChange={(data) => onChange(data, "phone")}
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="US"
                                />
                                {validatePhone && (
                                    <small className="text-danger">
                                        phone number must be valid <sup>*</sup>{" "}
                                    </small>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col
                    lg="3"
                    className={styles.frm_grp}
                    data-aos-duration="3000"
                    data-aos="fade-left"
                >
                    <button className={`theme-btn w-100 btn ${loading ? "btn_disabled":""}`} type="submit">
                    {loading ?  <BeatLoader color="#b4880c" /> : "Discuss the project"}
                    </button>
                </Col>
            </Row>
        </Form>
    )
}

export default ContactForm