import React from "react";
import styles from "./what-your-project.module.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoMdCall } from "react-icons/io";
import { IoMail } from "react-icons/io5";


const WhatYourProject = () => {
  return (
    <section className={styles.what_your_project}>
      <Container>
        <h2 className="text-center">What’s <span>YOUR</span></h2>
        <h2 className={`text-center ${styles.secondary}`}>next <span>PROJECT?</span></h2>
        <ul className={styles.what_your_project_ul}>
          <li>
            <p>Every great design starts with a conversation.</p>
            <p>Share your vision with us.</p>
          </li>
          <li>
            <Link to="tel:+13035980433" className={styles.secondary}><i><IoMdCall /></i>+1 303 598 0433</Link><br />
            <Link to="mailto:contact@denverdreambuilders.com"><i><IoMail /></i>contact@denverdreambuilders.com</Link>
          </li>
        </ul>
      </Container>
    </section>
  );
};

export default WhatYourProject;
