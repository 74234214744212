import React, { useRef } from "react";
import HomeBanner from "../../components/Home/HomeBanner";
import HomeServiceSec from "../../components/Home/HomeServiceSec";
import Portfolio from "../../components/globalComponent/Portfolio";
import HomeUniqueSec from "../../components/Home/HomeUniqueSec";
import HeadMetaSec from "../../components/HeadMetaSec";
import { HomePageData } from "../../components/Home/HomeData";
import Testimonials from "../../components/globalComponent/Testimonials";
import "./Home.scss";

const Home = () => {
    const scrollToElement = useRef(null);
    return (
        <>
            <HeadMetaSec HeadSecData={HomePageData.helmetSec} />
            <HomeBanner scrollToElement = {scrollToElement} />
            <HomeServiceSec refFirstService={scrollToElement} />
            <Portfolio />
            <HomeUniqueSec />
            <Testimonials/>
        </>
    )
}

export default Home