import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { home_service_list_bg_img_1, logo_hmg, small_logo, right_arrow } from "../../assets/images";
import { BiSupport } from "react-icons/bi";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedinIn, FaPinterestP } from "react-icons/fa";
import ModelPopup from '../../components/globalComponent/ModelPopup';
import "./Header.scss"

const Header = () => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  useEffect(() => {
    let elementId = document.getElementById("header");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  }, [pathname]);
  const dd = <><span className="bar-shape"></span><span className="bar-text">MENU</span></>
  useEffect(() => {
    if (show) {
      document.body.classList.add('stopscroll')
    }
    return () => document.body.classList.remove('stopscroll')
  }, [show]);
  useEffect(() => {
    setShow(false);
  }, [pathname]);

  const modelFormData = {
    btnName: <><span>Schedule a Consultation</span><i><BiSupport /></i></>,
  }

  return (
    <header id="header" className="th-header">
      <Container className="container-lg">
        <Navbar>
          <Link to="/" className="navbar-brand">
            <img src={logo_hmg} alt="logo" className="bg-logo" />
            <img src={small_logo} alt="logo" className="small-logo" />
          </Link>
          <Navbar.Toggle aria-controls="denver-navbar" />
          <Navbar.Collapse id="denver-navbar">
            <Nav className="ms-auto">
              {/* <Link to="/" className="theme-btn">Schedule a Consultation</Link> */}
              <ModelPopup modelFormData={modelFormData} />
              <button className={`dropdown-toggle nav-link ${show && 'change-shape'}`} onClick={() => setShow(!show)}>
                {dd}
              </button>
            </Nav>
          </Navbar.Collapse>

          <div className={`menu ${show && 'menushow'}`}>
            <div className="menuContent-lft">
              <div className="ins-text">
                <p className="mb-0">AN INSPIRING PORTFOLIO <Link to="/portfolio">View project<i className="ms-3"><img src={right_arrow} alt="View Project" /></i></Link></p>
              </div>
            </div>
            <div className="menuContent-right">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/custom-home-builders">New Homes</Link>
                </li>
                <li>
                  <Link to="/kitchen-remodeling-services">Kitchen Designing & Remodeling</Link>
                </li>

                <li>
                  <Link to="/bathroom-remodeling">Bathroom Designing & Remodeling</Link>
                </li>
                <li>
                  <Link to="/basement-remodeling">Basement Designing & Remodeling</Link>
                </li>
                <li>
                  <Link to="/general-contractors">General Contracting</Link>
                </li>
                <li>
                  <Link to="/floor-refinishing">Flooring</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="https://denverdreambuilders.com/blog/" target='_blank'>Blog</Link>
                </li>
              </ul>
              <img src={home_service_list_bg_img_1} className="bg-logo" alt="bg"/>
              <div className="mail-details-tp">
                <div className="hdr_social_link">
                  <Link to="https://www.facebook.com/denverdreambuilders" target="_blank">
                    <i><FaFacebook /></i>
                  </Link>
                  <Link to="https://twitter.com/denver_builders" target="_blank">
                    <i><FaTwitter /></i>
                  </Link>
                  <Link to="https://www.instagram.com/denverdreambuilders/" target="_blank">
                    <i><FaInstagram /></i>
                  </Link>
                  <Link to="https://www.linkedin.com/company/denver-dream-builders/" target="_blank">
                    <i><FaLinkedinIn /></i>
                  </Link>
                  <Link to="https://in.pinterest.com/denverdreambuilders/" target="_blank">
                    <i><FaPinterestP /></i>
                  </Link>
                </div>
                <div className="mail-details">
                  <span>T: <Link to="tel:+13035980433">+1 303 598 0433</Link></span>
                  <span>E: <Link to="mailto:contact@denverdreambuilders.com">contact@denverdreambuilders.com</Link></span>
                </div>
              </div>

            </div>
          </div>
        </Navbar>
      </Container>
    </header>
  );
};

export default Header;
