import React from "react";
import styles from './building-tomorrow-sec.module.scss';
import { Col, Container, Row } from "react-bootstrap";
import { ThreeText, building_tomorrow_img, home_service_list_bg_img_1, shedule_icn } from "../../assets/images";
import ModelForm from "../globalComponent/ModelPopup";

const BuildingTomorrowSec = () => {
    const modelFormData = {
        btnName: "Schedule Now"
    }
    return (
        <section className={styles.building_tomorrow_sec}>
            <Container>
                <Row>
                    <div className={styles.buildng_lft} data-aos="zoom-in" data-aos-duration="2000">
                        <img src={building_tomorrow_img} className={styles.building_tomorrow_sec_lft_img} alt="Building img" />
                    </div>
                    <div className={styles.pillers_sec}>
                        <img src={home_service_list_bg_img_1} className={styles.pillers_logo} alt="Piller logo" />
                        <div className={styles.pillers_sec_innr_tp}>
                            <h4 className="text-uppercase">Why <span>Denver</span> <span>Dream Builders?</span></h4>
                            <span className={styles.pillers_text} data-aos="zoom-in" data-aos-duration="3000">
                                <h3>
                                    <img src={ThreeText} alt="Pillars" />
                                </h3>
                                <span>PILLARS</span>
                            </span>
                        </div>
                    </div>
                    <div className={styles.buildng_rght}>
                        <div className="sub-heading">
                            <h2 className="text-uppercase"><span>Building tomorrow's </span>world with today's innovation</h2>
                        </div>
                        <div className={styles.shedule_sec}>
                            <img src={shedule_icn} alt="shedule icon" />
                            <h4>Free Consultation Plus $1500 Design Value!</h4>
                            <div className={styles.btn_sec}>
                                <ModelForm modelFormData={modelFormData} />
                            </div>
                        </div>
                    </div>
                </Row>
                <Row className={styles.building_tomorrow_btm_row}>
                    <Col lg="4" md="6" sm="6" data-aos="zoom-in" data-aos-duration="3000">
                        <div className={styles.tomorrow_btm_row_itm}>
                            <h2>01</h2>
                            <h5>Free Consultation</h5>
                            <p>As trusted builders in Denver, we offer complimentary consultations to discuss your vision, ensuring transparency and understanding from the start of your project.</p>
                        </div>
                    </Col>
                    <Col lg="4" md="6" sm="6" data-aos="zoom-in" data-aos-duration="2000">
                        <div className={styles.tomorrow_btm_row_itm}>
                            <h2>02</h2>
                            <h5>No Upfront Payment</h5>
                            <p>We believe in earning your trust, and don't require any advance payment, prioritizing your satisfaction and peace of mind.</p>
                        </div>
                    </Col>
                    <Col lg="4" md="6" sm="8" data-aos="zoom-in" data-aos-duration="1500">
                        <div className={styles.tomorrow_btm_row_itm}>
                            <h2>03</h2>
                            <h5>Delay Compensation</h5>
                            <p>We value your time, offering $200 per day compensation if there's any delay in delivering your service.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}


export default BuildingTomorrowSec