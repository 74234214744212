import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./privacy-page-banner.module.scss";

const ContactBanner = ({ BannerData }) => {
  return (
    <section className={styles.service_banner}>
      <Container className={styles.service_container}>
        <Row className="justify-content-center">
          <Col lg="10">
            <h1 className="text-center text-white text-uppercase mb-0">{BannerData.heading}</h1>
          </Col>
        </Row>
      </Container>
      <img src={BannerData.BannerImg} className={styles.service_banner_img} alt="Banner"/>
    </section>
  );
};
export default ContactBanner;