import React, { useRef } from "react";
import AboutSec from "../components/About/AboutSec";
import HeadMetaSec from "../components/HeadMetaSec";
import { AboutPageData } from "../components/About/AboutData";
import Testimonials from "../components/globalComponent/Testimonials";
import AboutCount from "../components/About/AboutCount";
import AboutContentSec from "../components/About/AboutContentSec";
import ContactBanner from "../components/Contact/ContactBanner";

const Home = () => {
    const scrollToElement = useRef(null)
    return (
        <>
            <HeadMetaSec HeadSecData={AboutPageData.helmetSec} />
            <ContactBanner scrollToElement = {scrollToElement} BannerData={AboutPageData.bannerData} />
            <AboutSec refToAboutSec= {scrollToElement} />
            <AboutCount aboutCountData={AboutPageData.aboutCountData}/>
            <AboutContentSec/>
            <Testimonials/>
        </>
    )
}

export default Home