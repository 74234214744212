const formClientId = {
    production:"Uhax4Drm0ipTVjVx",
    development:"Vz8FwKeO4VbGjiBu"  //testing
}

const formSubmitApiLink = "https://myleads.scrumdigital.in/api/leads";

const env = process.env.NODE_ENV;

export const server = {
    clientId : formClientId[env],
    formSubmitApiLink:formSubmitApiLink
}