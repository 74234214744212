import React from "react";
import styles from './about-content-sec.module.scss'


const AboutContentSec = () => {
    return (
        <section className={styles.about_sec_contnt}>
            <div className="container sub-heading">
                <h2 className="text-center">Meet the Craftsmen Behind Your Future  <span>Home: Our Story</span></h2>
                <p>At Denver Dream Builders, we believe in turning visions into reality. With over 25+ years of crafting spaces that reflect your dreams, our motto, "If you can dream it, we can build it," is more than just words—it's a promise. Our journey begins with a free consultation, where we listen to your ideas and aspirations. Our innovative computer design service then turns those concepts into reality. For just $1500, you can approve a design that's tailored just for you, with no upfront payment required.</p>
                <p>We understand the value of your time, which is why we commit to a punctual schedule, compensating $200 for each day of delay, ensuring we respect the timeline we've promised you. Our no advance payment policy is a testament to our confidence in our work and our commitment to your satisfaction.</p>
                <p>Our expertise spans across new home constructions, bespoke kitchen and bathroom designs, cozy yet functional basements, elegant flooring, and all-around general contracting. Each service is a reflection of our dedication to excellence, which is evident in the awards and certifications we've proudly earned over the years.</p>
                <p className="mb-0">"Building your dreams with integrity and craftsmanship" is not just our approach; it's our guarantee. Let Denver Dream Builders be the foundation to the home you've always wanted.</p>
            </div>
        </section>
    )
}

export default AboutContentSec