import React from 'react';
import styles from './ready-bring-sec.module.scss';
import { Col, Container, Row } from "react-bootstrap";
import { home_service_list_bg_img_1 } from "../../assets/images";
import ContactFormSubmit from '../globalComponent/ContactFormSubmit';

const ReadyBringSec = () => {
    return (
        <div className={`ready-bring-sec ${styles.ready_bring_sec} `}>
            <Container>
                <div className={`sub-heading text-center ${styles.sub_heading_tp} `}>
                    <h2 className="text-uppercase">Ready to bring your <span>dream project to life?</span></h2>
                    <Row className="justify-content-center">
                        <Col lg="5">
                            <p>Transform your dreams into reality with our expertise. Let's build something incredible together.</p>
                        </Col>
                    </Row>
                    <img src={home_service_list_bg_img_1} alt= "ready to being" />
                </div>
                <ContactFormSubmit location="page" />

            </Container>
        </div>
    )
}

export default ReadyBringSec;