import React, { useRef } from "react";
import { HomePageData } from "./HomeData";
import HomeServiceCompFirst from "./HomeServiceCompFirst";
import BigImgService from "./BigImgService";
import Cta from "../globalComponent/Cta";
import BuildingTomorrowSec from "./BuildingTomorrowSec";
import styles from './services-navigation-sec.module.scss';
import { scrollTargetElement } from "../../utils/scrollTargetElement";
import { Kitchen_H_Icn, Home_H_Icn, Bathroom_H_Icn, Basment_H_Icn, Contractor_H_Icn, Floor_H_Icn } from "../../assets/images";

const HomeServiceSec = ({ refFirstService }) => {
    const targetElement1 = useRef(null);
    const targetElement2 = useRef(null);
    const targetElement3 = useRef(null);
    const targetElement4 = useRef(null);
    const targetElement5 = useRef(null);
    const targetElement6 = useRef(null);
    const targetElementArr = [targetElement2, targetElement3, targetElement4, targetElement5, targetElement6]

    return (
        <>
            <section ref={refFirstService}>
                {/* {
                    HomePageData.ServiceList.map((list, i) => i < 1 && <HomeServiceCompFirst key={i} HomeServiceCompFirstData={list} refTarget ={null} />)
                } */}
                <HomeServiceCompFirst HomeServiceCompFirstData={HomePageData.ServiceList[0]} refTarget={null} />
            </section>
            <section className={`${styles["services_navigation_tp"]} sub-heading text-center`}>
                <h2 className="mb-0">Our <span>Services</span></h2>
                <div className={`${styles["services_navigation"]}`}>
                    <div className="container">
                        <ul className="row">
                            <li className="col-lg-4 col-6">
                                <button onClick={() => scrollTargetElement(targetElement1)}>
                                    <span className={`${styles["s_icn"]}`}>
                                        <img src={Kitchen_H_Icn} alt="Kitchen Icon" />
                                    </span>
                                    Kitchen Designing &<br /> Remodeling
                                </button>
                            </li>
                            <li className="col-lg-4 col-6">
                                <button onClick={() => scrollTargetElement(targetElement2)}>
                                    <span className={`${styles["s_icn"]}`}>
                                        <img src={Home_H_Icn} alt="Home Builders Icon" />
                                    </span>
                                    New Homes
                                </button>
                            </li>
                            <li className="col-lg-4 col-6">
                                <button onClick={() => scrollTargetElement(targetElement3)}>
                                    <span className={`${styles["s_icn"]}`}>
                                        <img src={Bathroom_H_Icn} alt="Bathroom Icon" />
                                    </span>
                                    Bathroom Designing &<br /> Remodeling
                                </button>
                            </li>
                            <li className="col-lg-4 col-6">
                                <button onClick={() => scrollTargetElement(targetElement4)}>
                                    <span className={`${styles["s_icn"]}`}>
                                        <img src={Basment_H_Icn} alt="Basement Icon" />
                                    </span>
                                    Basement Designing &<br /> Remodeling
                                </button>
                            </li>
                            <li className="col-lg-4 col-6">
                                <button onClick={() => scrollTargetElement(targetElement5)}>
                                    <span className={`${styles["s_icn"]}`}>
                                        <img src={Contractor_H_Icn} alt="General Contractors Icon" />
                                    </span>
                                    General Contracting
                                </button>
                            </li>
                            <li className="col-lg-4 col-6">
                                <button onClick={() => scrollTargetElement(targetElement6)}>
                                    <span className={`${styles["s_icn"]}`}>
                                        <img src={Floor_H_Icn} alt="Floor Icon" />
                                    </span>
                                    Flooring <br />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section style={{overflow:"hidden"}}>
                {/* {
                    HomePageData.ServiceList.map((list, i) => i >= 1 && <HomeServiceCompFirst key={i} HomeServiceCompFirstData={list} refTarget={targetElement1} />)
                } */}
                <HomeServiceCompFirst HomeServiceCompFirstData={HomePageData.ServiceList[1]} refTarget={targetElement1} />
            </section>
            <section>
                {
                    HomePageData.BigImgServiceData.map((list, i) => i <= 1 && (<BigImgService key={i} BigImgServiceData={list} refTarget={targetElementArr[i]} />))
                }
            </section>
            <BuildingTomorrowSec />
            <Cta />
            <section className="pdtb">
                {
                    HomePageData.BigImgServiceData.map((list, i) => i > 1 && (<BigImgService key={i} BigImgServiceData={list} refTarget={targetElementArr[i]} />))
                }
            </section>
        </>
    )
}


export default HomeServiceSec;