import React from "react";
import styles from './testimonials.module.scss';
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";

const RatingToStar = ({ rating }) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const renderStars = () => {
        const stars = [];
        // Full stars
        for (let i = 0; i < fullStars; i++) {
            stars.push(<li key={i}> <BsStarFill /> </li>);
        }
        // Half star
        if (hasHalfStar) {
            stars.push(<li key={stars.length}> <BsStarHalf /> </li>);
        }
        // Empty stars
        const remainingStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
        for (let i = 0; i < remainingStars; i++) {
            stars.push(<li key={fullStars + (hasHalfStar ? 1 : 0) + i}> <BsStar /></li>);
        }
        return stars;
    };

    return (
        <ul className={`star-list ${styles.star}`}> {renderStars()} </ul>
    );
}

export default RatingToStar;
