import React, { useRef } from "react";
import HeadMetaSec from "../components/HeadMetaSec";
import { ContactData } from "../components/Contact/ContactData";
import ContactBanner from "../components/Contact/ContactBanner";
import ContactPage from "../components/Contact/ContactPage"

const Home = () => {
    const scrollToElement = useRef(null);
    return (
        <>
            <HeadMetaSec HeadSecData={ContactData.helmetSec} />
            <ContactBanner scrollToElement= {scrollToElement} BannerData={ContactData.bannerData} />
            <ContactPage refToContact = {scrollToElement} />
        </>
    )
}

export default Home