import React from "react";
import styles from './contact-page-banner.module.scss';
import { Col, Container, InputGroup, Row, Form } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { BeatLoader } from "react-spinners";



const ContactPageForm = ({ validated, FormSubmit, onChange, validatePhone, phone, loading }) =>{
    return(
        <section className={styles.contact_page_map_sec}>
        <Container>
          <Row className="justify-content-center">
            <Col lg="9">
              <Form
                noValidate
                validated={validated}
                onSubmit={FormSubmit}
                className="contact-page-frm">
                <Row>
                  <Col lg="12" className="text-center">
                    <h5>REQUEST A QUOTE</h5>
                    <h2>How can we assist you?</h2>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <Form.Group>
                      <InputGroup hasvalidation ="true">
                        <Form.Control
                          type="text"
                          placeholder="Your Name *"
                          className={styles.frm_control}
                          required
                          name="fname"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Your Name.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col
                    lg="6"
                    md="6"
                    sm="6"
                  >
                    <div className="input-group">
                      <PhoneInput
                        maxLength={15}
                        placeholder="Phone Number"
                        required={true}
                        name="phone"
                        value={phone}
                        onChange={(data) => onChange(data, "phone")}
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                      />
                      {validatePhone && (
                        <small className="text-danger">
                          phone number must be valid <sup>*</sup>{" "}
                        </small>
                      )}
                    </div>
                  </Col>
                  <Col
                    lg="6"
                    md="6"
                    sm="6"
                    className={styles.frm_grp}
                  >
                    <Form.Group>
                      <InputGroup hasvalidation ="true">
                        <Form.Control
                          type="email"
                          placeholder="Your Email *"
                          required
                          name="email"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Your Email.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col
                    lg="6"
                    md="6"
                    sm="6"
                    className={styles.frm_grp}
                  >
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Subject"
                          required
                          name="subject"
                        />
                      </InputGroup>
                    </Form.Group>

                  </Col>
                  <Col lg="12">
                    <Form.Group className="input-group pb-3">
                      <Form.Control
                        placeholder="Description"
                        required
                        name="description"
                        as="textarea" />
                    </Form.Group>
                  </Col>
                  <Col
                    lg="12"
                    className="text-center"
                  >
                    <button className={`theme-btn text-uppercase btn ${loading ? "btn_disabled":""}`} type="submit">
                      {loading ?  <BeatLoader color="#b4880c" /> : "Submit"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d196282.0784212376!2d-105.16692671340913!3d39.764185746346755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b80aa231f17cf%3A0x118ef4f8278a36d6!2sDenver%2C%20CO%2C%20USA!5e0!3m2!1sen!2sin!4v1711452935890!5m2!1sen!2sin" frameBorder="0"></iframe>
      </section>

    )
}

export default ContactPageForm
