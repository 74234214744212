import { Service_page_Banner1 } from "../../assets/images";
export const ContactData = {
    helmetSec: {
        title: "Contact Us | Denver Dream Builders",
        description:
            "Connect with our construction experts for prompt support and solutions. We're ready to assist with your projects and inquiries. Contact us now!",
        keywords: "General Contractor in Denver, Construction Company, Custom home builders, General Contractor near me",
        conical: "contact-us",
    },
    bannerData: {
        heading: <>Get in Touch with Us</>,
        BannerImg: Service_page_Banner1,
    }
}