import { BasementPrImg1, BasementPrImg2, BasementPrImg3, BasementPrImg4, BasementPrImg5, BasementPrImg6, BasementPrImg7, BasementPrImg8, BasementPrImg9, BasementPrImg10, BasementPrImg11, BasementPrImg12, BasementPrImg13, BasementPrImg14, BathroomPrImg1, BathroomPrImg2, BathroomPrImg7, BathroomPrImg8, BathroomPrImg9, BathroomPrImg10, BathroomPrImg11, BathroomPrImg12, BathroomPrImg13, BathroomPrImg14, FloorPrImg1, FloorPrImg2, FloorPrImg3, FloorPrImg4, FloorPrImg5, FloorPrImg6, FloorPrImg7, FloorPrImg8, FloorPrImg9, FloorPrImg10, FloorPrImg11, FloorPrImg12, FloorPrImg13, FloorPrImg14, KitchenPrImg1, KitchenPrImg2, KitchenPrImg3, KitchenPrImg4, KitchenPrImg5, KitchenPrImg6, KitchenPrImg7, KitchenPrImg8, KitchenPrImg9, KitchenPrImg10, KitchenPrImg11, KitchenPrImg12, KitchenPrImg13, KitchenPrImg14, NewHomePrImg1, NewHomePrImg2, NewHomePrImg3, NewHomePrImg4, NewHomePrImg5, NewHomePrImg6, NewHomePrImg7, NewHomePrImg8, NewHomePrImg9, NewHomePrImg10, NewHomePrImg11, NewHomePrImg12, Service_page_Banner1, bathroomPortfolio2, bathroomPortfolio3, bathroomPortfolio4, bathroomPortfolio5 } from "../../assets/images";

export const PortfolioPageData = {
    helmetSec: {
        title: "Denver Dream Builders |Local Remodel Contractors",
        description:
            "Browse our portfolio of local remodel projects of kitchen, bathroom, basement, flooring & get inspired for your next home improvement! Book/Get Free Consultation!",
        conical: "portfolio",
    },
    bannerData: {
        heading: <>Portfolio</>,
        BannerImg: Service_page_Banner1,
    }
}

export const PortfolioContentSecData = [
    {
        id: 1,
        eventKey: "kitchendesigning",
        tabTitle: "Kitchen Designing",
        tabInnerImages: [
            {
                id: 1,
                alt: "kitchen1",
                src: KitchenPrImg1
            },
            {
                id: 2,
                alt: "kitchen2",
                src: KitchenPrImg2
            },
            {
                id: 3,
                alt: "kitchen3",
                src: KitchenPrImg3
            },
            {
                id: 4,
                alt: "kitchen4",
                src: KitchenPrImg4
            },
            {
                id: 5,
                alt: "kitchen5",
                src: KitchenPrImg5
            },
            {
                id: 6,
                alt: "kitchen6",
                src: KitchenPrImg6
            },
            {
                id: 7,
                alt: "kitchen7",
                src: KitchenPrImg7
            },
            {
                id: 8,
                alt: "kitchen8",
                src: KitchenPrImg8
            },
            {
                id: 9,
                alt: "kitchen9",
                src: KitchenPrImg9
            },
            {
                id: 10,
                alt: "kitchen10",
                src: KitchenPrImg10
            },
            {
                id: 11,
                alt: "kitchen11",
                src: KitchenPrImg11
            },
            {
                id: 12,
                alt: "kitchen12",
                src: KitchenPrImg12
            },
            {
                id: 13,
                alt: "kitchen13",
                src: KitchenPrImg13
            },
            {
                id: 14,
                alt: "kitchen14",
                src: KitchenPrImg14
            },
        ]
    },
    {
        id: 2,
        eventKey: "bathroomdesigning",
        tabTitle: "Bathroom Designing",
        tabInnerImages: [
            {
                id: 1,
                alt: "bathroom1",
                src: BathroomPrImg1
            },
            {
                id: 2,
                alt: "bathroom2",
                src: BathroomPrImg2
            },
            {
                id: 3,
                alt: "bathroom3",
                src: bathroomPortfolio2
            },
            {
                id: 4,
                alt: "bathroom4",
                src: bathroomPortfolio3
            },
            {
                id: 5,
                alt: "bathroom5",
                src: bathroomPortfolio4
            },
            {
                id: 6,
                alt: "bathroom6",
                src: bathroomPortfolio5
            },
            {
                id: 7,
                alt: "bathroom7",
                src: BathroomPrImg7
            },
            {
                id: 8,
                alt: "bathroom8",
                src: BathroomPrImg8
            },
            {
                id: 9,
                alt: "bathroom9",
                src: BathroomPrImg9
            },
            {
                id: 10,
                alt: "bathroom10",
                src: BathroomPrImg10
            },
            {
                id: 11,
                alt: "bathroom11",
                src: BathroomPrImg11
            },
            {
                id: 12,
                alt: "bathroom12",
                src: BathroomPrImg12
            },
            {
                id: 13,
                alt: "bathroom13",
                src: BathroomPrImg13
            },
            {
                id: 14,
                alt: "bathroom14",
                src: BathroomPrImg14
            },

        ]
    },
    {
        id: 3,
        eventKey: "basementdesigning",
        tabTitle: "Basement Designing",
        tabInnerImages: [
            {
                id: 1,
                alt: "basement1",
                src: BasementPrImg1
            },
            {
                id: 2,
                alt: "basement2",
                src: BasementPrImg2
            },
            {
                id: 3,
                alt: "basement3",
                src: BasementPrImg3
            },
            {
                id: 4,
                alt: "basement4",
                src: BasementPrImg4
            },
            {
                id: 5,
                alt: "basement5",
                src: BasementPrImg5
            },
            {
                id: 6,
                alt: "basement6",
                src: BasementPrImg6
            },
            {
                id: 7,
                alt: "basement7",
                src: BasementPrImg7
            },
            {
                id: 8,
                alt: "basement8",
                src: BasementPrImg8
            },
            {
                id: 9,
                alt: "basement9",
                src: BasementPrImg9
            },
            {
                id: 10,
                alt: "basement10",
                src: BasementPrImg10
            },
            {
                id: 11,
                alt: "basement11",
                src: BasementPrImg11
            },
            {
                id: 12,
                alt: "basement12",
                src: BasementPrImg12
            },
            {
                id: 13,
                alt: "basement13",
                src: BasementPrImg13
            },
            {
                id: 14,
                alt: "basement14",
                src: BasementPrImg14
            },
        ]
    },
    {
        id: 4,
        eventKey: "floring",
        tabTitle: "Flooring",
        tabInnerImages: [
            {
                id: 1,
                alt: "flooring1",
                src: FloorPrImg1
            },
            {
                id: 2,
                alt: "flooring2",
                src: FloorPrImg2
            },
            {
                id: 3,
                alt: "flooring3",
                src: FloorPrImg3
            },
            {
                id: 4,
                alt: "flooring4",
                src: FloorPrImg4
            },
            {
                id: 5,
                alt: "flooring5",
                src: FloorPrImg5
            },
            {
                id: 6,
                alt: "flooring6",
                src: FloorPrImg6
            },
            {
                id: 7,
                alt: "flooring7",
                src: FloorPrImg7
            },
            {
                id: 8,
                alt: "flooring8",
                src: FloorPrImg8
            },
            {
                id: 9,
                alt: "flooring9",
                src: FloorPrImg9
            },
            {
                id: 10,
                alt: "flooring10",
                src: FloorPrImg10
            },
            {
                id: 11,
                alt: "flooring11",
                src: FloorPrImg11
            },
            {
                id: 12,
                alt: "flooring12",
                src: FloorPrImg12
            },
            {
                id: 13,
                alt: "flooring13",
                src: FloorPrImg13
            },
            {
                id: 14,
                alt: "flooring14",
                src: FloorPrImg14
            },
        ]
    },
    {
        id: 6,
        eventKey: "newhomes",
        tabTitle: "New Homes",
        tabInnerImages: [
            {
                id: 1,
                alt: "new homes1",
                src: NewHomePrImg1
            },
            {
                id: 2,
                alt: "new homes2",
                src: NewHomePrImg2
            },
            {
                id: 3,
                alt: "new homes3",
                src: NewHomePrImg3
            },
            {
                id: 4,
                alt: "new homes4",
                src: NewHomePrImg4
            },
            {
                id: 5,
                alt: "new homes5",
                src: NewHomePrImg5
            },
            {
                id: 6,
                alt: "new homes6",
                src: NewHomePrImg6
            },
            {
                id: 7,
                alt: "new homes7",
                src: NewHomePrImg7
            },
            {
                id: 8,
                alt: "new homes8",
                src: NewHomePrImg8
            },
            {
                id: 9,
                alt: "new homes9",
                src: NewHomePrImg9
            },
            {
                id: 10,
                alt: "new homes10",
                src: NewHomePrImg10
            },
            {
                id: 11,
                alt: "new homes11",
                src: NewHomePrImg11
            },
            {
                id: 12,
                alt: "new homes12",
                src: NewHomePrImg12
            },
        ]
    },
]