import React from "react";
import styles from './portfolio.module.scss';
import { Col, Container, Row } from "react-bootstrap";
import {logo_hmg_old } from "../../assets/images";
import { Link } from "react-router-dom";

const Portfolio = () =>{
    return(
        <section className={styles.inspiring_sec}>
        <Container>
            <Row className={`${styles["inspiring_sec_row"]} align-items-end`}>
                <Col lg="5" md="4" className={styles.left_sec}>
                    <img src={logo_hmg_old} alt="logo" className="img-fluid" />
                </Col>
                <Col lg="6" md="8" className={`ms-auto ${styles.right_content_sec}`}>
                    <div className="sub-heading">
                        <h2 className="text-end text-uppercase">An Inspiring <span><Link to="/portfolio">Portfolio</Link></span></h2>
                    </div>
                    {/* <HomeUniqueSlider sliderImgList={portfolioImgs}/> */}
                </Col>
            </Row>
        </Container>
    </section>
    )
}

export default Portfolio;