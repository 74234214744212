import React from "react";
import ServiceBanner from "../components/Service/ServiceBanner";
import ServiceAbout from "../components/Service/ServiceAbout";
import { ServiceData } from "../components/Service/ServiceData";
import { useParams } from "react-router-dom";
import Cta from "../components/Service/Cta";
import BuildingTomorrowSec from "../components/Home/BuildingTomorrowSec";
import ServiceGallery from "../components/Service/ServiceGallery";
import WhatYourProject from "../components/Service/WhatYourProject";
import HeadMetaSec from "../components/HeadMetaSec";
import NotFound from "./NotFound";

const Service = () => {
    const params = useParams();
    const { name } = params || {};
    const myService = ServiceData.find(elem => elem.name === name);
    return (
        <>
            {
                myService ?
                    <>
                        <HeadMetaSec HeadSecData={myService.helmetSec} />
                        <ServiceBanner BannerData={myService.bannerData} />
                        <ServiceAbout ServiceAboutData={myService.ServiceAboutData} />
                        <Cta ServiceCtatData={myService.ServiceCtatData} />
                        <BuildingTomorrowSec />
                        <ServiceGallery serviceGalleryData={myService.serviceGalleryData} />
                        <WhatYourProject />
                    </>
                    : <NotFound />
            }
        </>
    )
}

export default Service