
export const scrollTargetElement = (targetId, h) =>{
    // const targetElement = document.getElementById(targetId);

    if (targetId && targetId.current) {
    //     const headerOffset = 300;
    //    const elementPosition =targetId.current.getBoundingClientRect().top;
    //    const offsetPosition = elementPosition + headerOffset + h;
    //     window.scrollTo({
    //         top: offsetPosition,
    //         behavior: "smooth",
    //     });
    targetId.current.scrollIntoView({ behavior: "smooth" });

    }

}