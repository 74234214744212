import React from "react";
import styles from './privacy-content-sec.module.scss'
import { Link } from 'react-router-dom';
const AboutContentSec = () => {
    return (
        <section className={styles.about_sec_contnt}>
            <div className="container sub-heading">
                <p>This Privacy Policy describes our policies and procedures on the collection, use and disclosure of your information when you use the service and tells you about your privacy rights and how the law protects you. </p>
                <p>We use your data to provide and improve the service. By using the service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
                <p>Your privacy is important to us. It is Denver Dream Builders's policy to respect your privacy regarding any information we may collect from you across our website, <strong><Link to="/">https://denverdreambuilders.com/.</Link></strong></p>
                <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
                <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
                <p>We don’t share any personally identifying information publicly or with third parties, except when required to by law.</p>
                <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
                <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services. Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. </p>
                <h3 className="mb-3">Online Privacy Policy Only</h3>
                <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website about the information that they share and/or collect. This policy does not apply to any information collected offline or via channels other than this website.</p>
                <h3 className="mb-3">Consent</h3>
                <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
                <h3 className="mb-3">Contact Us</h3>
                <p className="mb-0">If you have any questions about how we handle user data and personal information, feel free to contact us. <br/><strong><Link to="mailto:contact@denverdreambuilders.com"> Email: contact@denverdreambuilders.com</Link></strong></p>
            </div>
        </section>
    )
}

export default AboutContentSec