import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./service-page-banner.module.scss";
// import ContactForm from "../globalComponent/ContactForm";
// import { BiMobileAlt, BiUser, BiMailSend } from "react-icons/bi";
import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import ContactFormSubmit from "../globalComponent/ContactFormSubmit";
import Slider from "react-slick";

const ServiceBanner = ({ BannerData, sliderImgList }) => {
  const slider = React.useRef(null);
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 7000,
    speed: 500,
  };
  return (
    <section className={styles.service_banner}>
      <Container className={styles.service_container}>
        <Row className="justify-content-center align-items-center">
          <Col lg="6">
            <h1 className="text-white text-uppercase mb-0">{BannerData.heading}</h1>
          </Col>
          <Col lg="6">
            <div className={styles.frm_banner}>
              <h2>Book a Free Consultation</h2>
              <p className={styles.frm_banner_decription}>Schedule Your Free Consultation for Professional Solutions Today!</p>
              <ContactFormSubmit location="model" />
            </div>
          </Col>
        </Row>
      </Container>
      {/* <img src={BannerData.BannerImg} className={styles.service_banner_img} /> */}
      <Slider className="slider-service-page-innr" {...settings} ref={slider}>
      {
        BannerData?.BannerBgImg?.map((img, index)=>(
          <div className="item" key={index}>
          <img src={img} alt="Slider"/>
        </div>
        ))
      }
      </Slider>
    </section>
  );
};

export default ServiceBanner;
