import { Service_page_Banner1 } from "../../assets/images";

export const PrivacyData = {
    helmetSec: {
        title: "Denver Dream Builders",
        description:
            "Explore our privacy policy to understand how we safeguard your data, ensuring transparency, security, and respect for your privacy rights.",
        keywords: "Privacy Policy",
        conical: "privacy-policy",
    },
    bannerData: {
        heading: <>Privacy  Policy</>,
        BannerImg: Service_page_Banner1,
    },
}