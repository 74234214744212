import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ContactFormSubmit from "./ContactFormSubmit";
import modelImg from './../../assets/images/model_img.jpg'
import CrossIcon from "../AssetsComponent/CrossIcon";


const ModelPopup = ({modelFormData}) =>{
    const [showModalForm, setShowModalForm] = useState(false);
    return(
        <>
        <Button
        className={`theme-btn ${modelFormData?.customClass}`}
        onClick={() => setShowModalForm(true)}
      >
        {modelFormData.btnName}
      </Button>
      <Modal
        size="lg"
        show={showModalForm}
        onHide={() => setShowModalForm(false)}
        aria-labelledby="example"
        className="contact-form-modal"
      >
      <div className="close_btn">
        <button type="button" onClick={()=>setShowModalForm(false)}><CrossIcon/></button>
      </div>
        <Modal.Body>
          <div className="d-flex flex-wrap">
            <div className="left_img_sec">
                <img src={modelImg} alt="modal" className="img-fluid" />
            </div>
            <div className="modal_right_content">
                <h2>Ready to bring your <span>dream project to life?</span></h2>
                <p>Transform your dreams into reality with our expertise.  Let's build something incredible together.</p>

                <ContactFormSubmit location="model" hideForm={setShowModalForm} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
        </>

    )
}


export default ModelPopup;