// import { type } from "@testing-library/user-event/dist/type";
import { basement_img, bath_img, floring_img, general_contracting_img, home_about_us, kitchen_design_img, new_home_img } from "../../assets/images";
import { Link } from "react-router-dom";

export const HomePageData = {
    helmetSec: {
        title: "Denver Dream Builders - Residential Renovation Contractors | Home Remodeling Experts",
        description:
            "Denver's trusted residential general contractors for 25+ years, specializing in custom home builds, kitchen, bathroom, basement remodeling, and more.",
        keywords: "General Contractor in Denver, Construction Company, Custom home builders, General Contractor near me",
        conical: "/",
    },
    ServiceList: [
        {
            id: 1,
            pId: "whyDenver",
            imgSrc: home_about_us,
            bgLogo: true,
            alt: "service1",
            contentSec: {
                title: <>Welcome to Denver <span>Dream Builders</span></>,
                desc: [
                    <>
                        Denver Dream Builders is your trusted local renovation contractor serving excellence for more than 25 years. Our commitment is to make your journey as rewarding as the result that truly sets us apart. We offer a comprehensive range of residential remodeling services in Denver, including - <><Link to="kitchen-remodeling-services">kitchen</Link></>, <><Link to="bathroom-remodeling">bathroom</Link></>, <><Link to="floor-refinishing">flooring</Link></>,   <><Link to="basement-remodeling">basement remodeling</Link></> and <><Link to="general-contractors">general contracting</Link></>.
                    </>,
                    "Whether you're thinking of revamping a single room or undertaking a full-scale renovation, our expert team, as your reliable home improvement contractor, is here to guide you every step of the way. We also offer a FREE consultation, allowing you to discuss your ideas and vision with our experienced team.",
                    "As your trusted residential renovation contractor, our team is dedicated to bringing your ideas to life and managing every aspect of your project to ensure its success from start to finish. To make this offer even more attractive, we're providing a complimentary consultation valued at $1500 for design services. Plus, we offer compensation for any delays on our part, ensuring this applies only to setbacks from our side and not due to any external factors.",
                    "Ready to get started? Give us a call to schedule your free consultation today!",
                ],
            },
            btnSec: {
                type: "model",
                btnName: <>Let's Catch Up Over Coffee</>
            }
        },
        {
            id: 2,
            pId: "kitchenRemodeling",
            btnSec: {
                btnName: <>Read More</>,
                type: "link",
                href: "/kitchen-remodeling-services"
            },
            bgLogo: false,
            contentAlignLeft: true,
            orderImg: "order-1 order-lg-2 kitchen-img-hmg",
            orderContentSec: "order-2 order-lg-1 mt-4 mt-lg-0",
            imgSrc: kitchen_design_img,
            alt: "service1",
            contentSec: {
                title: <><span>Kitchen </span> Designing  & Remodeling Services</>,
                desc: "Bring your dream kitchen to life with our Kitchen Remodelling Services, specializing in modern kitchen design. Whether you're envisioning a complete transformation or just a few updates, our skilled team is dedicated to constructing a space that complements your tastes and way of life. We offer a range of styles to suit your preferences, including rustic, contemporary, traditional, and transitional kitchens, ensuring a personalized approach to every project.",
            }
        }
    ],
    BigImgServiceData: [
        {
            id: 1,
            pId: "newHome",
            bgLogo: true,
            imgSrc: new_home_img,
            alt: "new Home",
            heading: <><span>New</span> Homes</>,
            desc: "As one of the most seasoned New Home Builders and home remodeling experts, we take pride in guiding you through every detail of the new home construction journey. Trusted as luxury custom home builders in Denver, we celebrate the significant decision of creating a new custom home with you. ",
            links: "/custom-home-builders"
        },
        {
            id: 2,
            pId: "bathroomDesign",
            orderContentSec: "order-lg-1",
            orderImgSec: "order-lg-2",
            customPadding: "ps-0",
            imgSrc: bath_img,
            alt: "bath sec",
            heading: <><span>Bathroom Designing</span> & Remodeling Services</>,
            desc: "Transform your bathroom with our design and construction expertise. We not only build new bathrooms from scratch but also excel in remodeling existing ones to give them a completely fresh and unique look, specializing in bathroom remodeling. Our focus is on providing affordable bathroom renovations in Denver without compromising on quality or style.",
            links: "/bathroom-remodeling"
        },
        {
            id: 3,
            pId: "basementRedesign",
            imgSrc: basement_img,
            alt: "basement img",
            heading: <><span>Basement Designing</span> & Remodeling Services</>,
            desc: "Transform your underutilized space with our basement design services, where creativity meets functionality. As expert basement designers, we specialize in basement renovations that redefine your living space. Whether it's a cozy family room, a home theatre, or a personal gym, we're here to bring your vision to life, ensuring every square foot reflects your style and meets your needs.",
            links: "/basement-remodeling"
        },
        {
            id: 4,
            pId: "generalContracting",
            orderContentSec: "order-lg-1",
            orderImgSec: "order-lg-2",
            customPadding: "ps-0",
            imgSrc: general_contracting_img,
            alt: "general contracting img",
            heading: <><span>General</span> Contracting</>,
            desc: "As a Construction Company, we're your trusted partner in turning dreams into reality. We handle everything from beginning to end with accuracy and care, with a focus on remodeling to revitalise your workspace. With us, your project is more than just construction—it's a journey toward excellence.",
            links: "/general-contractors"
        },
        {
            id: 5,
            pId: "flooring",
            imgSrc: floring_img,
            alt: "flooring",
            heading: <>Flooring</>,
            desc: "Give your floors a fresh start with our flooring refinishing services. We're here to help fix up any damage and make your floors look shiny and new again. Whether you have hardwood, tile, or laminate, our skilled team is all about doing a top-notch job with lots of care. We want your floors to bring out the best in your home, making it feel cozy and inviting. Let's get those floors looking great!",
            links: "/floor-refinishing"
        },
    ]
}