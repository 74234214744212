import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./contact-page-banner.module.scss";
import { PrtfolioBgImg } from "../../assets/images";

const ContactBanner = () => {
  return (
    <section className={styles.service_banner}>
      <Container className={styles.service_container}>
        <Row className="justify-content-center">
          <Col lg="10">
            <h1 className="text-center text-white text-uppercase mb-0">Portfolio</h1>
          </Col>
        </Row>
      </Container>
      <img src={PrtfolioBgImg} className={styles.service_banner_img} alt="Portfolio"/>
    </section>
  );
};
export default ContactBanner;