import { BiMobileAlt, BiUser, BiMailSend } from "react-icons/bi";
import styles from "./contactForm.module.scss";
import { Form, InputGroup } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { BeatLoader } from "react-spinners";

const ContactModelForm = ({ validated, FormSubmit, onChange, validatePhone, phone, loading}) => {
	// const handleVerify = useCallback((token) => {
	// 	// setToken(token);
	// 	console.log("token", token)
	// });

	return (
		<Form
			className={`${styles.ready_bring_frm} `}
			noValidate
			validated={validated}
			onSubmit={FormSubmit}
		>
			<div>

				<div
					className={styles.frm_grp}
				>
					<Form.Group>
						<InputGroup hasValidation>
							<i>
								<BiUser />
							</i>
							<Form.Control
								type="text"
								placeholder="Your Name"
								className={styles.frm_control}
								required
								name="fname"
							/>
							<Form.Control.Feedback type="invalid">
								Please Enter Your Name.
							</Form.Control.Feedback>
						</InputGroup>
					</Form.Group>
				</div>
				<div
					className={styles.frm_grp}
				>
					<Form.Group>
						<InputGroup hasValidation>
							<i>
								<BiMailSend />
							</i>
							<Form.Control
								type="email"
								placeholder="Your Email"
								className={styles.frm_control}
								required
								name="email"
							/>
							<Form.Control.Feedback type="invalid">
								Please Enter a Valid Email Address.
							</Form.Control.Feedback>
						</InputGroup>
					</Form.Group>
				</div>
				<div
					className={styles.frm_grp}
				>
					<div className={styles.phone_number}>
						<i>
							<BiMobileAlt />
						</i>
						<PhoneInput
							maxLength={15}
							placeholder="Phone Number"
							required={true}
							name="phone"
							value={phone}
							onChange={(data) => onChange(data, "phone")}
							international
							countryCallingCodeEditable={false}
							defaultCountry="US"
						/>
					</div>
					{validatePhone && (
						<small className="text-danger">
							phone number must be valid <sup>*</sup>{" "}
						</small>
					)}
				</div>
				<div className={styles.btn_sec}
				>
					<button className={`btn theme-btn w-100 ${loading ? "btn_disabled":""}`} type="submit">
					{loading ?  <BeatLoader color="#b4880c" /> : "Discuss the project"}
						{/* Discuss the project */}
					</button>
				</div>
			</div>
		</Form>
	)
}

export default ContactModelForm