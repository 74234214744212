import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { home_service_list_bg_img_1 } from "../../assets/images";
import { TestimonialData } from "./TestimonialData";
import "slick-carousel/slick/slick.css";
import styles from './testimonials.module.scss'
import RatingToStar from "./RatingToStar";


const Testimonials = () => {
    var settingsbtm = {
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3500,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <section className={`testimonial-slider ${styles.testimonial_sec}`}>
            <Container>
                <div className={`sub-heading text-center ${styles.sub_hading}`}>
                    <h2>Hear From Our <span>Satisfied Clients</span></h2>
                    <p>Real Experiences and  Transformative Results</p>
                    <img src={home_service_list_bg_img_1} className={styles.sub_hading_logo} alt="bg logo" />
                </div>
                <Row className="justify-content-center">
                    <Col md="8" className="col-md-10">
                        <Slider className="slider-content slider-for" {...settingsbtm}>
                            {
                                TestimonialData?.map((list, i) => (
                                    <div key={list.id}>
                                        <div className={styles.testimonial} >
                                            <div className={`testimonial-info ${styles.testimonial_info}`}>
                                                <h3 className={!list.designation ? styles.fix_height : ""}>{list.name}</h3>
                                                <RatingToStar rating={list.rating ? list.rating : 5} />
                                                {list.designation ? <span>{list.designation}</span> : null}
                                                <div className={`testimonial-desc ${styles.desc_sec}`}>
                                                    {
                                                        Array.isArray(list.desc) ? list.desc.map((desc, i) => <p key={i}>{desc}</p>) : <p>{list.desc}</p>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Testimonials

// import React, { useEffect, useRef, useState } from "react";
// import { Container,Row,Col } from "react-bootstrap";
// import Slider from "react-slick";
// import { home_service_list_bg_img_1 } from "../../assets/images";
// import RatingToStar from "./RatingToStar";
// import { TestimonialData } from "./TestimonialData";
// import "slick-carousel/slick/slick.css";
// import styles from './testimonials.module.scss'


// const Testimonials = () => {
//     const [nav1, setNav1] = useState(null);
//     const [nav2, setNav2] = useState(null);
//     let sliderRef1 = useRef(null);
//     let sliderRef2 = useRef(null);
//     useEffect(() => {
//         setNav1(sliderRef1)
//         setNav2(sliderRef2)
//     }, [])
//     const settings = {
//         dots: false,
//         infinite: true,
//         arrows: false,
//         autoplay: true,
//         autoplaySpeed: 1500,
//         speed: 1300,
//         centerMode: true,
//         centerPadding: '30px',
//         slidesToShow: 5,
//         responsive: [
//             {
//                 breakpoint: 991,
//                 settings: {
//                     slidesToShow:3,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 576,
//                 settings: {
//                     slidesToShow:1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     };
//     const settingsbtm = {
//         dots: false,
//         infinite: true,
//         arrows: false,
//         speed: 1300,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//     };

//     return (
//         <section className={`testimonial-slider ${styles.testimonial_sec}`}>
//             <Container>
//                 <div className={`sub-heading text-center ${styles.sub_hading}`}>
//                     <h2>Hear From Our <span>Satisfied Clients</span></h2>
//                     <p>Real Experiences and  Transformative Results</p>
//                     <img src={home_service_list_bg_img_1} className={styles.sub_hading_logo} alt="bg"/>
//                 </div>
//                 <Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)} className="slideshow slider-nav" {...settings}>
//                     {
//                         TestimonialData?.map((list) => (
//                             <div key={list.id}>
//                                 <div className={styles.testimonial} >
//                                     <RatingToStar rating={list.rating ? list.rating : 5} />
//                                     <div className={styles.pic}>
//                                         <img src={list.TestimonialImg} alt="testimonial"/>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))
//                     }
//                 </Slider>
//                 <Row className="justify-content-center">
//                     <Col md="8" className="col-md-10">
//                         <Slider asNavFor={nav1} ref={slider => (sliderRef2 = slider)} className="slider-content slider-for" {...settingsbtm}>
//                             {
//                                 TestimonialData?.map((list, i) => (
//                                     <div key={list.id}>
//                                         <div className={styles.testimonial} >
//                                             <div className={`testimonial-info ${styles.testimonial_info}`}>
//                                                 <h3 className={!list.designation ? styles.fix_height : ""}>{list.name}</h3>
//                                                 {list.designation ? <span>{list.designation}</span> : null}
//                                                 <div className={`testimonial-desc ${styles.desc_sec}`}>
//                                                     {
//                                                         Array.isArray(list.desc) ? list.desc.map((desc, i) => <p key={i}>{desc}</p>) : <p>{list.desc}</p>
//                                                     }
//                                                 </div>

//                                             </div>
//                                         </div>
//                                     </div>
//                                 ))
//                             }
//                         </Slider>
//                     </Col>
//                 </Row>
//             </Container>
//         </section>
//     )
// }
// export default Testimonials