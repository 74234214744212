import React from "react";
import styles from './home-unique-sec.module.scss';
import Slider from "react-slick";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const HomeUniqueSlider = ({sliderImgList}) => {
    const slider = React.useRef(null);
    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        fade: true,
        cssEase: 'ease-in-out',
        touchThreshold: 100
    };
    return (
        <div className={styles.unique_design_slider}>
            <button
                className={`${styles.slick_prev} d-none d-sm-block`}
                onClick={() => slider?.current?.slickPrev()}
            >
                <i>
                    <BsArrowLeft />
                </i>
            </button>
            <button
                className={`${styles.slick_next} d-none d-sm-block`}
                onClick={() => slider?.current?.slickNext()}
            >
                <i>
                    <BsArrowRight />
                </i>
            </button>
            <Slider className="slideshow" {...settings} ref={slider}>
            {
                sliderImgList?.map((list, i)=>(
                    <div className="item" key={i}>
                    <img src={list.imgSrc} alt={list.alt} />
                </div>
                ))
            }
            </Slider>
        </div>
    )
}

export default HomeUniqueSlider;