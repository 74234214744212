import { Service_page_Banner1 } from "../../assets/images";
import { FaDollarSign, FaHandHoldingDollar } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { MdOutlinePersonSearch  } from "react-icons/md";

export const AboutPageData = {
    helmetSec: {
        title: "About Us | Denver Dream Builders",
        description:
            "Transforming visions into reality for over 25 years, our construction expertise turns ambitious projects into iconic structures. Request a quote.",
        keywords: "General Contractor in Denver, Construction Company, Custom home builders, General Contractor near me",
        conical: "about-us",
    },
    bannerData: {
        heading: <>Crafting Excellence: Commitment to Your Vision</>,
        BannerImg: Service_page_Banner1,
    },
    aboutCountData: [
        {
            id: 1,
            countNo: <i><FaDollarSign /></i>,
            countTitle: "No Upfront Payment"
        },
        {
            id: 2,
            countNo: <i><BiSupport /></i>,
            countTitle: "Free Consultation"
        },
        {
            id: 3,
            countNo: <i><FaHandHoldingDollar /></i>,
            countTitle: "Delay Compensation"
        },
        {
            id: 4,
            countNo: <i><MdOutlinePersonSearch /></i>,
            countTitle: "Transparency"
        },
    ]
}