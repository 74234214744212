import React, {  useEffect, useState } from "react";
// import { BiUser, BiMobileAlt, BiMailSend } from "react-icons/bi";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isValidPhoneNumber } from "react-phone-number-input";
import { server } from "../../config/config";
import ContactForm from "./ContactForm";
import ContactModelForm from "./ContactModelForm";
import ContactPageForm from "../Contact/ContactPageForm";
import { useLocation } from "react-router-dom";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactFormSubmit = ({ location = "page", hideForm}) => {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const MySwal = withReactContent(Swal);
    // const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState();
    const [validatePhone, setValidatePhone] = useState();
    const locationPage = useLocation();
    const { executeRecaptcha } = useGoogleReCaptcha();

    async function FormSubmit(event) {
        event.preventDefault();

        let { fname, email, subject, description } = event.target;
        let formData = {
            clientID: server.clientId,
            name: fname.value,
            email: email.value,
            mobile: phone,
            uuid: window.localStorage.getItem("scrum-visitor-trackingId"),
            landing_page : window.location.href,
        };
        let formDataContactPage = {
                    clientID: server.clientId,
                    name: fname.value,
                    email: email.value,
                    mobile: phone,
                    uuid: window.localStorage.getItem("scrum-visitor-trackingId"),
                    subject:subject ? subject.value : '',
                    description:description ? description.value : '',
                    landing_page : window.location.href,
                };
                if(!executeRecaptcha){
                    console.log('Execute recaptcha not yet available');
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: "Execute recaptcha not yet available",
                        confirmButtonText: 'OK'
                      });
                    return;
                }else{
                    if(phone && isValidPhoneNumber(phone)){
                        setValidatePhone(false);
                        setLoading(true);
                        axios({
                            method: "POST",
                            url: `${server.formSubmitApiLink}`,
                            headers: { "content-type": "application/json" },
                            // data: JSON.stringify(formData),
                            data: location==="contactPage" ?JSON.stringify(formDataContactPage) :JSON.stringify(formData),
                        })
                            .then((result) => {
                                setLoading(false);
                                event.target.reset();
                                setPhone("");
                                if(hideForm){
                                    hideForm(false)
                                }
                                MySwal.fire({
                                    title: <p>Success!!</p>,
                                    html: (
                                        <p>
                                            We have received your information and one of our representative
                                            will get in touch with you shortly to schedule an appointment.
                                        </p>
                                    ),
                                });
                               
                                // setLoading(false);
                            })
                            .catch((error) => {
                                setLoading(false);
                                console.log("error", error);
                            })
                       }else{

                        setValidatePhone(true)

                       }
                }
        setValidated(true);
    }

    useEffect(()=>{
        setValidatePhone(false)
        setValidated(false);
    },[locationPage])
    const handleOnChange = (e, type) => {
        if (type === "phone") {
            if (e && isValidPhoneNumber(e)) {
                setPhone(e);
                setValidatePhone(false);
            } else {
                setValidatePhone(true);
                return;
            }
        }
    };
    return (
        location === "page" ?
            <ContactForm loading={loading} validated={validated} phone={phone} FormSubmit={FormSubmit} onChange={handleOnChange} validatePhone={validatePhone}   />
            :
            location==="contactPage"? <ContactPageForm loading={loading} validated={validated} phone={phone} FormSubmit={FormSubmit} onChange={handleOnChange} validatePhone={validatePhone}  />
            :<ContactModelForm loading={loading} validated={validated} phone={phone} FormSubmit={FormSubmit} onChange={handleOnChange} validatePhone={validatePhone}  />
    )
};

export default ContactFormSubmit;
