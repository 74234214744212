import React from 'react';
import styles from "./cta.module.scss";
// import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ModelForm from '../globalComponent/ModelPopup';

const Cta = ({ ServiceCtatData }) => {
  // const { pathname } = useLocation();
  // const [show, setShow] = useState(false);
  // useEffect(() => {
  //   setShow(false);
  // }, [pathname]);
  // const modelFormData = {
  //   buttoncontent: "Catch Up Over Coffee",
  // }
  return (
    <section className={styles.hmg_cta}>
      <Container>
        <Row className="justify-content-center">
          <Col lg="9">
            <div className="sub-heading text-center">
              <h2>
                {ServiceCtatData.heading}
              </h2>
              <div className={styles.btn_sec}>
                <ModelForm modelFormData={ServiceCtatData.buttoncontent} />
                {/* <button className="theme-btn">{ServiceCtatData.buttoncontent}</button> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Cta;
