import { Kitchen_Item_img1, } from "../../assets/images";
import { KitchenBannerBg1, KitchenBannerBg2, KitchenBannerBg3, CustomHomeBuilderBanner1, CustomHomeBuilderBanner2, CustomHomeBuilderBanner3, BathroomRBanner1, BathroomRBanner2, BathroomRBanner3, BasementRBanner1, BasementRBanner2, BasementRBanner3, GeneralCBanner1, GeneralCBanner2, GeneralCBanner3, FloorFBanner1, FloorFBanner2, FloorFBanner3, S1_Gallery_Img_1, S1_Gallery_Img_2, S1_Gallery_Img_3, S1_Gallery_Img_4, S1_Gallery_Img_5, S1_Gallery_Img_6, S1_Gallery_Img_7, S2_Gallery_Img_1, S2_Gallery_Img_2, S2_Gallery_Img_3, S2_Gallery_Img_4, S2_Gallery_Img_5, S2_Gallery_Img_6, S2_Gallery_Img_7, S3_Gallery_Img_1, S3_Gallery_Img_2, S3_Gallery_Img_3, S3_Gallery_Img_4, S3_Gallery_Img_5, S3_Gallery_Img_6, S3_Gallery_Img_7, S4_Gallery_Img_1, S4_Gallery_Img_2, S4_Gallery_Img_3, S4_Gallery_Img_4, S4_Gallery_Img_5, S4_Gallery_Img_6, S4_Gallery_Img_7, S5_Gallery_Img_1, S5_Gallery_Img_2, S5_Gallery_Img_3, S5_Gallery_Img_4, S5_Gallery_Img_5, S5_Gallery_Img_6, S5_Gallery_Img_7, S6_Gallery_Img_1, S6_Gallery_Img_2, S6_Gallery_Img_3, S6_Gallery_Img_4, S6_Gallery_Img_5, S6_Gallery_Img_6, S6_Gallery_Img_7, Kitchen_Item_img2, Kitchen_Item_img3, CustomHomeBuildersItem_img1, CustomHomeBuildersItem_img2, CustomHomeBuildersItem_img3, BathroomRemoldingItem_img1, BathroomRemoldingItem_img2, BathroomRemoldingItem_img3, BasementRemolding_img1, BasementRemolding_img2, BasementRemolding_img3, GeneralContractors_img1, GeneralContractors_img2, GeneralContractors_img3, Floor_Refinishingimg1, Floor_Refinishingimg2, Floor_Refinishingimg3 } from "../../assets/images/service-page";
import { Link } from "react-router-dom";

export const ServiceData = [
    {
        name: 'kitchen-remodeling-services',
        helmetSec: {
            title: "Complete Kitchen Remodeling Contractors in Denver | Kitchen Cabinet Renovation Services",
            description:
                "Full-service kitchen remodeling and design contractors. Experience unmatched quality with our expert kitchen renovation services in Denver. FREE consultation!",
            keywords: "Kitchen Remodeling Services in Denver, Kitchen Designers in Denver, small kitchen remodel, Kitchen Remodeling Company in Denver, modern kitchen design, kitchen contractors near me",
            conical: "kitchen-remodeling-services",
        },
        bannerData: {
            heading: <>Kitchen Remodeling in Denver</>,
            BannerBgImg: [KitchenBannerBg1, KitchenBannerBg2, KitchenBannerBg3],
        },
        ServiceAboutData: {
            heading: 'Transform Your Kitchen: From Inspiration to Installation',
            description: <><p className="mb-5">Welcome to your kitchen transformation journey, where your vision meets our expertise. Explore a world of innovative designs and useful modifications made to fit your lifestyle. Our comprehensive home kitchen remodeling services in Denver guarantee a smooth process, leaving you with a magnificent kitchen that makes every entrance a statement, whether it's a full-scale renovation or a streamlined kitchen cabinets refacing. You can rely on <><Link to="/">Denver Dream Builders</Link></> for complete kitchen remodeling.</p></>,
            ctabuttonheading: {
                btnName: 'Start Transforming'
            },
            serviceItem: [
                {
                    id: 1,
                    bgLogo: true,
                    rightAlignContent: true,
                    subTitle: "consultation",
                    title: <>Designing Your Dream Kitchen</>,
                    imgSrc: Kitchen_Item_img1,
                    desc: <>
                        <p>Get started to bring your dream kitchen to life with professional kitchen cabinets and renovation contractors. Meet our friendly kitchen designers to discuss your vision, budget, and timeline, and discover how we can bring your ideas to life. Whether you opt for a virtual chat from the comfort of your home, we're dedicated to assisting you through every phase, from accurate measurements to the ultimate installation. Let's infuse your ideas of modern high end kitchen remodeling, making your space more than just an area - let's transform it into a true reflection of your lifestyle and dreams. Join us, and together, we'll create a kitchen that truly feels like home.</p>
                    </>
                },
                {
                    id: 2,
                    rightAlignContent: false,
                    afterContent: true,
                    orderImg: "order-lg-2 order-1",
                    orderContent: "order-lg-1 order-2",
                    subTitle: "Design Planning",
                    title: <>Shaping Your Concept Design</>,
                    imgSrc: Kitchen_Item_img2,
                    desc: <>
                        <p>Upgrade your kitchen with the modern and functional pantry cabinets you deserve. Our concept design phase is where creativity meets functionality. We'll closely explore your vision for dreamy kitchen and storage concerns - combined with innovative solutions. This crucial phase ensures your kitchen renovation doesn't just look good but it functions flawlessly for your needs. We don't believe in cookie-cutter designs – your custom cabinets start with a blueprint that reflects your unique goals.</p>
                    </>
                },
                {
                    id: 3,
                    bgLogo: true,
                    rightAlignContent: true,
                    subTitle: "Spectrum of Services",
                    title: <>Detail Design</>,
                    imgSrc: Kitchen_Item_img3,
                    desc: <>
                        <p>Explore our extensive collection of services, designed to transform your kitchen from a space for cooking to the center of your house. Our process starts with discussing your requirements in detail whether you're looking for White kitchen renovation, U shaped, Rustic, Mediterranean or expecting something that could fit a small budget renovation. From granite countertops to modern cabinet styles, we offer a wide range of options to elevate your kitchen's aesthetics. We handle the installation process, ensuring everything is built to last and reflects your unique style. From traditional to modern, our kitchen cabinet renovation options are made to optimize space and improve functionality. If you're looking for a total makeover, our comprehensive kitchen overhauls redefine spaces with innovative concepts and premium craftsmanship, guaranteeing a kitchen that's not just gorgeous but also a reflection of your goals and lifestyle. </p>
                    </>
                },
            ]
        },
        ServiceCtatData: {
            heading: <><span>Revitalize Your Space</span> - Begin Your Kitchen Makeover Now</>,
            buttoncontent: {
                btnName: 'Book Now',
            },
        },

        serviceGalleryData: [
            {
                id: 1,
                imgSrc: S1_Gallery_Img_1,
                alt: "gallery"
            },
            {
                id: 2,
                imgSrc: S1_Gallery_Img_2,
                alt: "gallery"
            },
            {
                id: 3,
                imgSrc: S1_Gallery_Img_3,
                alt: "gallery"
            },
            {
                id: 4,
                imgSrc: S1_Gallery_Img_4,
                alt: "gallery"
            },
            {
                id: 5,
                imgSrc: S1_Gallery_Img_5,
                alt: "gallery"
            },
            {
                id: 6,
                imgSrc: S1_Gallery_Img_6,
                alt: "gallery"
            },
            {
                id: 7,
                imgSrc: S1_Gallery_Img_7,
                alt: "gallery"
            },
        ]
    },
    {
        name: 'custom-home-builders',
        helmetSec: {
            title: "Local Custom Home Builders in Denver | Luxury Home Builders Contractors",
            description:
                "High-end contruction contractors in Denver, CO. Experience high-quality craftsmanship with Denver Dream Builders, a renowned luxury home builders for 25+ years.",
            keywords: "Custom Home Builders in Denver, General Contractors near me, home builders in denver, new home construction in denver, remodeling contractors near me",
            conical: "custom-home-builders",
        },
        bannerData: {
            heading: <>Custom Home Builders in Denver</>,
            BannerBgImg: [CustomHomeBuilderBanner1, CustomHomeBuilderBanner2, CustomHomeBuilderBanner3],
        },
        ServiceAboutData: {
            heading: 'Crafting Your Dream Home: Foundations to Finishes',
            description: <><p className="mb-5">Welcome to <><Link to="/">Denver Dream Builders</Link></> , your custom home builders in Denver CO, who turn your vision of a perfect home into our blueprint for creation. From the initial foundation to the final finishes, our expertise lies in transforming ideas into reality. Our home builder contractors specializes in new home construction and home remodeling, we ensure every detail reflects your personal style and meets our standard of excellence. Embark on crafting your dream home with your local building construction contractors, where new visions meet remodeling, with material costs covered by the client.</p></>,
            ctabuttonheading: {
                btnName: 'Start Your Project'
            },
            serviceItem: [
                {
                    id: 1,
                    subTitle: "consultation",
                    title: <>Consultation & Design</>,
                    imgSrc: CustomHomeBuildersItem_img1,
                    desc: <>
                        <p>Begin your home-building journey with a personalized consultation where your visions and requirements take center stage. As your chosen luxury new home builders and home renovation contractors, Denver Dream Builders dedicates time to understand your lifestyle, preferences, and the unique features you envision for your new home. Together, we'll craft a custom design that confines your dreams, ensuring every space is tailored to fit your life perfectly, whether it's crafting a new building from the ground up or transforming an existing space through renovation.</p>
                    </>
                },
                {
                    id: 2,
                    orderImg: "order-lg-2 order-1",
                    orderContent: "order-lg-1 order-2",
                    subTitle: "Design Planning",
                    title: <>Construction & Craftsmanship</>,
                    imgSrc: CustomHomeBuildersItem_img2,
                    desc: <>
                        <p>With the blueprint in hand, our skilled craftsmen, the finest home builders in Denver, bring your design to life. We prioritize quality and precision at every step, from laying the foundation to installing the final fixtures. Our commitment to excellence ensures that your new home, a testament to the expertise of Denver Dream Builders and our team of home builders, is not only beautiful but built to last. We use high-quality materials and state-of-the-art construction techniques, hallmarks of our work as renowned home builders.</p>
                    </>
                },
                {
                    id: 3,
                    subTitle: "Spectrum of Services",
                    title: <>Personalization & Finishing Touches</>,
                    imgSrc: CustomHomeBuildersItem_img3,
                    desc: <>
                        <p>The final stage is where your home truly becomes yours. Select from a wide range of finishing options, including flooring, paint colors, fixtures, and fittings, that reflect your personal style. Our team of interior construction contractor will be guiding you throughout the selection process, making sure every detail contributes to the warmth and character of your new home. With Denver Dream Builders, every corner of your residence will speak volumes about your taste and personality.</p>
                    </>
                },
            ]
        },
        ServiceCtatData: {
            heading: <>Create the Space Where <span>Memories Will Flourish:</span> Your New Home Awaits!</>,
            buttoncontent: {
                btnName: 'Start Today',
            },
        },

        serviceGalleryData: [
            {
                id: 1,
                imgSrc: S2_Gallery_Img_1,
                alt: "gallery"
            },
            {
                id: 2,
                imgSrc: S2_Gallery_Img_2,
                alt: "gallery"
            },
            {
                id: 3,
                imgSrc: S2_Gallery_Img_3,
                alt: "gallery"
            },
            {
                id: 4,
                imgSrc: S2_Gallery_Img_4,
                alt: "gallery"
            },
            {
                id: 5,
                imgSrc: S2_Gallery_Img_5,
                alt: "gallery"
            },
            {
                id: 6,
                imgSrc: S2_Gallery_Img_6,
                alt: "gallery"
            },
            {
                id: 7,
                imgSrc: S2_Gallery_Img_7,
                alt: "gallery"
            },
        ]
    },
    {
        name: 'bathroom-remodeling',
        helmetSec: {
            title: "Denver Bathroom Remodel Contractors | Local Bathroom Renovation Services",
            description:
                "Book a free consultation for your bathroom remodeling services in Denver at +1 303 598 0433. Get started with expert contractors today. 25+ Years of Experience!",
            keywords: " bathroom remodeling services in denver, shower remodeling, Bathroom remodel, bathroom renovations, Bathroom Remodeling Contractors in denver,  bathroom remodeling near me",
            conical: "/bathroom-remodeling",
        },
        bannerData: {
            heading: <>Bathroom Remodeling in Denver </>,
            BannerBgImg: [BathroomRBanner1, BathroomRBanner2, BathroomRBanner3],
        },
        ServiceAboutData: {
            heading: 'Transform Your Space, Redefine Bathroom Luxury',
            description: <><p className="mb-5">Denver Dream Builders is your premier choice for bathroom remodeling and bathroom design, dedicated to transforming your space and redefining the essence of luxury. Our bathroom remodel contractors ensure innovative design and craftsmanship exceed expectations, with clients responsible for material costs. Your bathroom will be a true haven of comfort and luxury when you work with <><Link to="/">Denver Dream Builders</Link></>, your local bathroom remodel contractors because every element is thoughtfully chosen to maximize both practicality and visual appeal.</p></>,
            ctabuttonheading: {
                btnName: 'Start Now'
            },
            serviceItem: [
                {
                    id: 1,
                    subTitle: "consultation",
                    title: <>Personalized Consultation & Design</>,
                    imgSrc: BathroomRemoldingItem_img1,
                    desc: <>
                        <p>Begin your journey with a personalized consultation where our expert bathroom renovation contractors work closely with you to understand your vision and requirements. Leveraging years of experience, we offer tailored advice on small bathroom designs and comprehensive renovations alike, ensuring your ideas are the foundation of the project. Our design phase is meticulous, incorporating your preferences with our creative solutions to draft the perfect blueprint for your bathroom transformation. </p>
                    </>
                },
                {
                    id: 2,
                    orderImg: "order-lg-2 order-1",
                    orderContent: "order-lg-1 order-2",
                    subTitle: "Design Planning",
                    title: <>Precision in Execution & Craftsmanship</>,
                    imgSrc: BathroomRemoldingItem_img2,
                    desc: <>
                        <p>With the design in place, Denver Dream Builders brings your vision to life with unmatched precision and craftsmanship. Our bathroom remodeling services focus on meticulous attention to detail, ensuring each tile, fixture, and finish matches the agreed design perfectly. We are dedicated to quality, using advanced techniques and materials to ensure your bathroom renovation is both beautiful and built to last.</p>
                    </>
                },
                {
                    id: 3,
                    subTitle: "Spectrum of Services",
                    title: <>Final Walkthrough</>,
                    imgSrc: BathroomRemoldingItem_img3,
                    desc: <>
                        <p>The completion of your bathroom renovation is marked by a comprehensive final walkthrough, led by your dedicated bathroom remodeling contractor. This crucial step ensures every aspect of the project meets our high standards and, more importantly, your expectations. At Denver Dream Builders, we're not satisfied until you are, promising a sanctuary of comfort and style that stands the test of time. With a commitment to excellence and a keen focus on customer satisfaction, we ensure your bathroom transformation is an effortless and rewarding experience from start to finish.</p>
                    </>
                },
            ]
        },
        ServiceCtatData: {
            heading: <>Envision Perfection: Begin <span>Your Bathroom Oasis</span> Transformation!</>,
            buttoncontent: {
                btnName: 'Build with Us',
            },
        },

        serviceGalleryData: [
            {
                id: 1,
                imgSrc: S3_Gallery_Img_1,
                alt: "gallery"
            },
            {
                id: 2,
                imgSrc: S3_Gallery_Img_2,
                alt: "gallery"
            },
            {
                id: 3,
                imgSrc: S3_Gallery_Img_3,
                alt: "gallery"
            },
            {
                id: 4,
                imgSrc: S3_Gallery_Img_4,
                alt: "gallery"
            },
            {
                id: 5,
                imgSrc: S3_Gallery_Img_5,
                alt: "gallery"
            },
            {
                id: 6,
                imgSrc: S3_Gallery_Img_6,
                alt: "gallery"
            },
            {
                id: 7,
                imgSrc: S3_Gallery_Img_7,
                alt: "gallery"
            },
        ]
    },
    {
        name: 'basement-remodeling',
        helmetSec: {
            title: "Local Basement Remodeling Contractors in Denver | Basement Renovation Experts",
            description:
                "Denver Dream Builders, your professional basement remodeling and finishing contractors in Denver, CO. Call us at +1 303 598 0433. Book a FREE consultation.",
            keywords: "Basement Remodeling in denver, basement finishing, basement renovation, basement remodel near me",
            conical: "/basement-remodeling",
        },
        bannerData: {
            heading: <>Basement Remodeling Services </>,
            BannerBgImg: [BasementRBanner1, BasementRBanner2, BasementRBanner3],
        },
        ServiceAboutData: {
            heading: 'Revitalize Your Home: Basement Design and Remodeling Excellence',
            description: <><p className="mb-5">Elevate your home with our basement remodeling contractors at <><Link to="/">Denver Dream Builders</Link></>. Our basement remodeling turns spaces vibrant, tailored to your lifestyle, with clients responsible for the cost of materials. Whether it's a cozy family room, an energetic game zone, or a private home theater, our dedicated team ensures a seamless combination of comfort and style. Experience the ultimate in lower-level luxury with Denver Dream Builders.</p></>,
            ctabuttonheading: {
                btnName: 'Start Your Project'
            },
            serviceItem: [
                {
                    id: 1,
                    subTitle: "consultation",
                    title: <>Consultation: Your Vision, Our Mission</>,
                    imgSrc: BasementRemolding_img1,
                    desc: <>
                        <p>Begin your basement transformation journey with a personalized consultation. Our local basement remodeling contractors are dedicated to understanding your unique vision for your basement's potential. We consider your desires for a functional and inviting space, whether it's a lively entertainment hub, a tranquil retreat, or an innovative basement house design. This initial step is crucial for tailoring our services to your specific needs, ensuring the finished basement exceeds your expectations.</p>
                    </>
                },
                {
                    id: 2,
                    orderImg: "order-lg-2 order-1",
                    orderContent: "order-lg-1 order-2",
                    subTitle: "Design Planning",
                    title: <>Design Planning: Crafting Your Ideal Space</>,
                    imgSrc: BasementRemolding_img2,
                    desc: <>
                        <p>With your vision clearly outlined, we embark on the design phase. Our expert basement renovation contractors will provide you with a detailed plan, incorporating your personal style and our innovative design solutions. We focus on basement finishing touches that make the space truly yours, from selecting the right materials to optimizing the layout for comfort and usability. Our goal is to create a design that blends seamlessly with your home's overall aesthetic and functional demands.</p>
                    </>
                },
                {
                    id: 3,
                    subTitle: "Spectrum of Services",
                    title: <>Execution and Transformation: Turning Dreams into Reality</>,
                    imgSrc: BasementRemolding_img3,
                    desc: <>
                        <p>Once the design is finalized, our skilled team of basement remodeling contractors gets to work, bringing your dream basement to life. The renovation process is handled with the utmost professionalism, precision, and dedication to quality. We manage every aspect of the transformation, ensuring each stage of the basement finishing process is executed flawlessly. With a commitment to craftsmanship and a keen eye for detail, we ensure your basement renovation is a resounding success, providing you with a space to enjoy for years to come.</p>
                    </>
                },
            ]
        },
        ServiceCtatData: {
            heading: <>Craft Your Foundation, Design <span>Your Dream Basement</span></>,
            buttoncontent: {
                btnName: 'Begin Transformation',
            },
        },

        serviceGalleryData: [
            {
                id: 1,
                imgSrc: S4_Gallery_Img_1,
                alt: "gallery"
            },
            {
                id: 2,
                imgSrc: S4_Gallery_Img_2,
                alt: "gallery"
            },
            {
                id: 3,
                imgSrc: S4_Gallery_Img_3,
                alt: "gallery"
            },
            {
                id: 4,
                imgSrc: S4_Gallery_Img_4,
                alt: "gallery"
            },
            {
                id: 5,
                imgSrc: S4_Gallery_Img_5,
                alt: "gallery"
            },
            {
                id: 6,
                imgSrc: S4_Gallery_Img_6,
                alt: "gallery"
            },
            {
                id: 7,
                imgSrc: S4_Gallery_Img_7,
                alt: "gallery"
            },
        ]
    },
    {
        name: 'general-contractors',
        helmetSec: {
            title: "General Contractors for Residential Remodels, New Home Construction in Denver",
            description:
                "Premier general contractor and custom builder professionals for any residential construction project throughout Denver, CO. Call +1 303 598 0433.",
            keywords: "General Contractor in Denver, Construction Company, remodeling contractors near me, residential contractors near me",
            conical: "/general-contractors",
        },
        bannerData: {
            heading: <>General Contracting</>,
            BannerBgImg: [GeneralCBanner1, GeneralCBanner2, GeneralCBanner3],
        },
        ServiceAboutData: {
            heading: 'General Contracting Services: Building Excellence, Delivering Dreams',
            description: <><p className="mb-5"><><Link to="/">Denver Dream Builders</Link></> is your go-to construction company, if you want an improved building experience. Our specialty as a licensed general construction contractor is providing perfection, precision, and creativity to every single task. Your project is in capable hands from the start to the finish due to our expertise as a leading <><Link to="/custom-home-builders">custom builder</Link></> and residential local general contractor in Denver. Transform your space with our creative and meticulous approach - your vision, our execution. Material costs are client's responsibility.</p></>,
            ctabuttonheading: {
                btnName: 'Start Your Project'
            },
            serviceItem: [
                {
                    id: 1,
                    subTitle: "consultation",
                    title: <>Consultation: Your Vision, Our Mission</>,
                    imgSrc: GeneralContractors_img1,
                    desc: <>
                        <p>Our journey together begins with a detailed consultation, where your vision takes center stage. We prioritize your goals, ensuring our role as your general contractors in Denver is to guide, plan, and execute with your aspirations in mind. Whether it's a custom home build or a remodeling project, we listen intently, laying the groundwork for a partnership that realizes your construction dreams to the fullest.</p>
                    </>
                },
                {
                    id: 2,
                    orderImg: "order-lg-2 order-1",
                    orderContent: "order-lg-1 order-2",
                    subTitle: "Design Planning",
                    title: <>Project Design: Blueprinting Your Vision</>,
                    imgSrc: GeneralContractors_img2,
                    desc: <>
                        <p>Crafting the blueprint of your dream build is where our expertise as a custom builder shines. Our skilled team of remodeling contractors, adept in the latest design trends and construction techniques, will work with you to create a detailed plan that reflects your lifestyle and preferences. As remodeling contractors, we make sure every design element is in line with your vision since we are skilled at designing environments that are both aesthetically pleasing and useful.</p>
                    </>
                },
                {
                    id: 3,
                    subTitle: "Spectrum of Services",
                    title: <>Construction Execution: Realizing Your Vision</>,
                    imgSrc: GeneralContractors_img3,
                    desc: <>
                        <p>With the blueprint set, our licensed general contractor team moves forward with transforming concepts into reality. Our construction process is defined by clear communication, rigorous quality control, and a commitment to timelines and budgets. Denver Dream Builders stands out among residential general contractors for our meticulous attention to detail and dedication to craftsmanship. We manage every phase of construction and remodeling, ensuring your project is completed to the highest standards of excellence.</p>
                    </>
                },
            ]
        },
        ServiceCtatData: {
            heading: <> Craft Your Dream Project with Our  <span> Premier Services</span></>,
            buttoncontent: {
                btnName: 'Connect Now',
            },
        },

        serviceGalleryData: [
            {
                id: 1,
                imgSrc: S5_Gallery_Img_1,
                alt: "gallery"
            },
            {
                id: 2,
                imgSrc: S5_Gallery_Img_2,
                alt: "gallery"
            },
            {
                id: 3,
                imgSrc: S5_Gallery_Img_3,
                alt: "gallery"
            },
            {
                id: 4,
                imgSrc: S5_Gallery_Img_4,
                alt: "gallery"
            },
            {
                id: 5,
                imgSrc: S5_Gallery_Img_5,
                alt: "gallery"
            },
            {
                id: 6,
                imgSrc: S5_Gallery_Img_6,
                alt: "gallery"
            },
            {
                id: 7,
                imgSrc: S5_Gallery_Img_7,
                alt: "gallery"
            },
        ]
    },
    {
        name: 'floor-refinishing',
        helmetSec: {
            title: "Residential Flooring Contractors in Denver | Licensed Flooring Restoration Experts",
            description:
                "Denver Dream Builders, professional flooring contractors located in Denver, CO. Top-quality flooring and remodeling services. 25+ Years of Experience.",
            keywords: "Floor Refinishing Services in Denver, Floor Restoration Services, Flooring Contractor near me, Floor Installtaion, remodeling contractors",
            conical: "/floor-refinishing",
        },
        bannerData: {
            heading: <>Floor Refinishing & Restoration</>,
            BannerBgImg: [FloorFBanner1, FloorFBanner2, FloorFBanner3],
        },
        ServiceAboutData: {
            heading: 'Bringing Floors Back to Life, Enhancing Home with Every Step',
            description: <><p className="mb-5">At <><Link to="/">Denver Dream Builders</Link></>, our workmanship are always a step ahead from the rest. Let the expert licensed flooring contractors brings new life to your existing flooring. From hardwood to laminate, we ensure each surface receives the care and expertise it deserves. You can expect the highest quality results with our local flooring contractors coupled with a personalized consultation. We tailor our approach to suit your home's unique style and character. Let us restore the shine to your floors! Please note, material costs will be covered by the client. Reach out to get started.</p></>,
            ctabuttonheading: {
                btnName: 'Start Your Project'
            },
            serviceItem: [
                {
                    id: 1,
                    subTitle: "consultation",
                    title: <>Comprehensive Floor Assessment</>,
                    imgSrc: Floor_Refinishingimg1,
                    desc: <>
                        <p>Our process begins with a thorough assessment of your flooring, laying the groundwork for effective restoration. Our team of Custom Flooring Specialists meticulously examines every inch of your space to identify areas in need of Floor Repair or complete rejuvenation. This initial evaluation is crucial in devising a tailored strategy that addresses specific damages and wear, ensuring our Floor Restoration services are precisely aligned with your flooring's condition and your home's overall aesthetic.</p>
                    </>
                },
                {
                    id: 2,
                    orderImg: "order-lg-2 order-1",
                    orderContent: "order-lg-1 order-2",
                    subTitle: "Design Planning",
                    title: <>Personalized Floor Remodeling Solutions</>,
                    imgSrc: Floor_Refinishingimg2,
                    desc: <>
                        <p>Following the assessment, we embark on the design phase, where your vision for your floors takes shape. Our Floor Remodeling Services are designed to transform your space, offering a range of materials and finishes to suit your style. Whether you're looking for a classic hardwood finish, modern tile, or sustainable options, our Custom Flooring Specialists work closely with you to select and design the perfect flooring solution, ensuring every detail aligns with your personal taste and functional needs.</p>
                    </>
                },
                {
                    id: 3,
                    subTitle: "Spectrum of Services",
                    title: <>Floor Repair and Installation: Bringing Designs to Life</>,
                    imgSrc: Floor_Refinishingimg3,
                    desc: <>
                        <p>With the blueprint set, our licensed general contractor team moves forward with transforming concepts into reality. Our construction process is defined by clear communication, rigorous quality control, and a commitment to timelines and budgets. Denver Dream Builders stands out among residential general contractors for our meticulous attention to detail and dedication to craftsmanship. We manage every phase of construction and remodeling, ensuring your project is completed to the highest standards of excellence.</p>
                    </>
                },
            ]
        },
        ServiceCtatData: {
            heading: <> Revitalize Your Home with <span> Expert Floor Refinishing </span> Services</>,
            buttoncontent: {
                btnName: 'Build Now',
            },
        },

        serviceGalleryData: [
            {
                id: 1,
                imgSrc: S6_Gallery_Img_1,
                alt: "gallery"
            },
            {
                id: 2,
                imgSrc: S6_Gallery_Img_2,
                alt: "gallery"
            },
            {
                id: 3,
                imgSrc: S6_Gallery_Img_3,
                alt: "gallery"
            },
            {
                id: 4,
                imgSrc: S6_Gallery_Img_4,
                alt: "gallery"
            },
            {
                id: 5,
                imgSrc: S6_Gallery_Img_5,
                alt: "gallery"
            },
            {
                id: 6,
                imgSrc: S6_Gallery_Img_6,
                alt: "gallery"
            },
            {
                id: 7,
                imgSrc: S6_Gallery_Img_7,
                alt: "gallery"
            },
        ]
    }
]