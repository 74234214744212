import React from "react";
import { useEffect } from "react";
import Header from "./element/Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./element/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import GoTop from './GoTop'
import ReadyBringSec from "../components/Home/ReadyBringSec";


const MainLayout = () => {
    const location = useLocation();
    useEffect(() => {
        AOS.init();
        AOS.init({ disable: 'mobile' })
    }, [])
    return (
        <>
            <Header />
            <Outlet />
            {
                location.pathname !== "/contact-us" && <ReadyBringSec />
            }

            <Footer />
            <GoTop scrollStepInPx="100" delayInMs="10.50" />
        </>
    )
}


export default MainLayout