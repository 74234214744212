import React from "react";
import styles from "./service-page-gallery.module.scss";
import { Container } from "react-bootstrap";
import LightGallery from "lightgallery/react";
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';


const ServiceGallery = ({ serviceGalleryData }) => {
  return (
    <section className={styles.service_page_gallery_sec}>
      <Container>
        <div className="gallery_sec_innr">
          <LightGallery mode="lg-fade" plugins={[lgZoom]}>
            {serviceGalleryData?.map((gallery, i) => <a key={i} className="gallery-item" data-src={gallery.imgSrc}><img className="img-fluid" src={gallery.imgSrc} alt="kitchen Design"/></a>)}
          </LightGallery>
        </div>
      </Container>
    </section>
  );
};

export default ServiceGallery;
