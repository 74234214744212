import React from "react";
import styles from './about-count-up.module.scss'
import { Col, Container, Row } from "react-bootstrap";


const AboutCount = ({ aboutCountData }) => {
    return (
        <section className={styles.about_count}>
            <Container className={styles.about_company_details}>
                <div className={styles.about_company_innrs}>
                    <Row>
                        {
                            aboutCountData?.map((list, i) => (
                                <Col lg="3" md="6" sm="6" key={i}>
                                    <div className={styles.company_dtls_item}>
                                        <span>{list.countNo}</span>
                                        <p>{list.countTitle}</p>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </Container>
        </section>

    )
}


export default AboutCount;