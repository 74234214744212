import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import styles from "./service-page-about.module.scss";
import { useLocation } from "react-router-dom";
import ModelForm from '../globalComponent/ModelPopup';
import { home_service_list_bg_img_1 } from '../../assets/images';
const ServiceAbout = ({ ServiceAboutData }) => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(false);
  }, [pathname]);

  return (
    <section className={styles.service_about}>
      <Container>
        <Row className="justify-content-center">
          <Col lg="10" className="justify-content-center text-center">
            <h2>{ServiceAboutData.heading}</h2>
            {ServiceAboutData.description}
            <ModelForm modelFormData={ServiceAboutData.ctabuttonheading} />
          </Col>
        </Row>
        <div className={styles.service_item_sec}>
          {
            ServiceAboutData?.serviceItem?.map((item, i) => (
              <ul className="row" key={i}>
                <li className={`col-lg-6 ${item.orderImg ? item.orderImg : "order-1"}`}>
                {
                   item?.bgLogo && <div className={styles.bg_img_sec_after}>
                        <img src={home_service_list_bg_img_1} alt="bg 1" />
                    </div>
                }
                  <img src={item.imgSrc} className="img-fluid" alt="Service Item" />
                </li>
                <li className={`${styles["service_item_contnt"]} ${item?.afterContent ? styles.afterContent :""} col-lg-6 ${item.orderContent ? item.orderContent : "order-1"}` }>
                  <div className={`${item.rightAlignContent ? "pe-0" : "ps-0"}`}>
                    <h6 className="text-uppercase">{item.subTitle}</h6>
                    <h2>{item.title}</h2>
                    {item.desc}
                  </div>
                </li>
              </ul>
            ))
          }
        </div>
      </Container>
    </section>
  );
};

export default ServiceAbout;
