import { TestimnlImg1, TestimnlImg2, TestimnlImg3, TestimnlImg4, TestimnlImg5, TestimnlImg6 } from "../../assets/images";

export const TestimonialData = [
    {
        id: 1,
        TestimonialImg: TestimnlImg1,
        name: "Madeleine K.",
        designation: "San Francisco, CA",
        desc: "Dale Horton and his crew offer expert building, renovation and remodeling! They replaced my failing balcony, renovated an old spa room into a bright, beautiful sunroom, and remodeled my attic, installing new insulation, dry wall and lighting, finishing with expert painting.  For each project a detailed bid proved to be quite accurate, and the promised timeline was adhered to.  Dale also completed urgently needed electrical and plumbing repairs as well as repairing my swamp cooler.  He is friendly, approachable and proceeds quite independently, communicating progress openly.  Would I hire this company again?  No question about it---they are the best!  And it would be hard to find a better value for the quality they provide!",
        rating: 4.5
    },
    {
        id: 2,
        TestimonialImg: TestimnlImg2,
        name: "Molly Q.",
        designation: "Santa Fe, NM",
        desc: "I can not say enough good things about Dale Horton and team! His calm and trustworthy demeanor make any difficult project look easy, and as the homeowner, we felt comfortable and confident with the project planning and execution. Dale not only manages the project, he is hands-on and engaged from start to finish as an expert craftsman. We were most impressed by his innovative problem solving when dealing with our custom requests and ideas. His reliability, honesty and flexibility were refreshing to find in a GC. He treats his clients and his crew with the upmost respect and friendliness, and delivered quality above our expectations.  Dale is Denver's best kept secret, and we feel so lucky to have had him on two of our home renovation projects (one being an addition to a townhome, and the other a complete floor-to-ceiling full home remodel.) We will definitely use Dale for all our home renovation needs in the future!",
        rating: 4.5
    },
    {
        id: 3,
        TestimonialImg: TestimnlImg3,
        name: "Gail Quick",
        // designation: "Santa Fe, NM",
        desc: "So pleased with the renovation Denver Dream Builder did for me. They communicated well every step of the way and had great solutions for my 1905 home issues! Last two pics are before!",
        rating: 5,
    },
    {
        id: 4,
        TestimonialImg: TestimnlImg4,
        name: "Jack",
        // designation: "Santa Fe, NM",
        desc: "Dale did an amazing job with my apartment.  I have been living in the apartment for several years and the apartment still looks brand new.  The longevity and durability of the materials is a testament to the craftsmanship.  I highly recommend Dale and  Denver Dream Builder for your next project.  Dale is very thorough, and his team does a great job.",
        rating: 5,
    },
    {
        id: 5,
        TestimonialImg: TestimnlImg5,
        name: "Melanie Nguyen",
        // designation: "Santa Fe, NM",
        desc: [
            "I have had the pleasure of working with Dale for several years now on many jobs, and I cannot express enough how truly amazing he has been as a contractor. His work has consistently exceeded my expectations, and I am more than satisfied with the results.",
            "One of the aspects I appreciate most about working with Dale is his reliability. He is consistently punctual, and deadlines are always met without compromising the quality of his work. It's refreshing to collaborate with someone who values both efficiency and precision.",
            "Beyond his technical skills, Dale is also a pleasure to work with on a personal level. His communication is clear and open, and he is always willing to address any concerns or questions that may arise during the project. This level of transparency creates a positive and collaborative working environment.",
            "In summary, I wholeheartedly recommend Dale as a contractor. His unwavering commitment to excellence, consistent high-quality work, and professionalism set him apart. If you're seeking a reliable and skilled contractor, look no further – Dale is the one you can trust to get the job done right."
        ],
        rating: 4,
    },
    {
        id: 6,
        TestimonialImg: TestimnlImg6,
        name: "Carolyn Hill",
        // designation: "Santa Fe, NM",
        desc: "Dale and team were committed and dedicated to making our house a home! New floors, kitchen cabinets, kitchen counter tops/sink, and more. Dale completely changed the floor plan on the first level making our home more livable and comfortable for entertaining family and friends. Thank you Dale and team!  Happy to  share my experience if referrals are needed.",
        rating: 5,
    },
]