import React, { useEffect, useState } from 'react';
import styles from './home-service-comp-first.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { home_service_list_bg_img_1 } from '../../assets/images';
import { Link, useLocation } from "react-router-dom";
import ModelForm from '../globalComponent/ModelPopup';


const HomeServiceCompFirst = ({ HomeServiceCompFirstData, refTarget }) => {
    const { pathname } = useLocation();
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(false);
    }, [pathname]);

    return (
        <div className={styles.hmg_service_list} id={ HomeServiceCompFirstData?.pId} ref={refTarget}>
            <Container>
                <Row className="align-items-center">
                    <Col xl="6" lg="5" data-aos-duration="2000" data-aos="fade-right" className={`text-center ${HomeServiceCompFirstData.orderImg ? HomeServiceCompFirstData.orderImg : "order-1"} `} >
                        <img src={HomeServiceCompFirstData?.imgSrc} alt={HomeServiceCompFirstData?.alt} className="img-fluid" />
                    </Col>
                    <Col xl={6} lg="7" data-aos-duration="2000" data-aos="fade-left" className={` ${HomeServiceCompFirstData.orderContentSec ? HomeServiceCompFirstData.orderContentSec : "order-2"} `}>
                        <div className={`${styles.right_content_sec} ${HomeServiceCompFirstData?.contentAlignLeft ? "ps-0" : ""}`}>
                            <div className="sub-heading">
                                <h2>{HomeServiceCompFirstData?.contentSec?.title}</h2>
                                {
                                    Array.isArray(HomeServiceCompFirstData?.contentSec?.desc) ? HomeServiceCompFirstData?.contentSec?.desc.map((arr, i) => (<p key={i}>{arr}</p>))
                                        : <p className="mb-0">{HomeServiceCompFirstData?.contentSec?.desc}</p>
                                }

                            </div>
                            {
                                HomeServiceCompFirstData?.btnSec && <div className={styles.btn_sec}>
                                    {
                                        HomeServiceCompFirstData?.btnSec.type==="link" ? <Link to={HomeServiceCompFirstData?.btnSec?.href} className={`${styles["read_more_text"]}`} >{HomeServiceCompFirstData?.btnSec.btnName}</Link>
                                        :
                                        <ModelForm modelFormData={HomeServiceCompFirstData?.btnSec} />
                                    }

                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                {
                    HomeServiceCompFirstData?.bgLogo && <div className={styles.bg_img_sec_after}>
                        <img src={home_service_list_bg_img_1} alt="bg 1" />
                    </div>
                }
            </Container>
        </div>
    )
}


export default HomeServiceCompFirst;