import React from 'react';

const CrossIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.02" height="17.02" viewBox="0 0 17.02 17.02">
            <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-16.995 8.51) rotate(-45)">
                <path id="Path_42126" data-name="Path 42126" d="M18,7.5V28.569" transform="translate(0.035)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Path_42127" data-name="Path 42127" d="M7.5,18H28.569" transform="translate(0 0.035)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </g>
        </svg>
    )
}

export default CrossIcon;