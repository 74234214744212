import React from "react";
import { useState } from 'react';
import styles from './portfolio-page-sec.module.scss';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { PortfolioContentSecData } from "./PortfolioData";


const AboutContentSec = () => {
    const [key, setKey] = useState('kitchendesigning');
    return (
        <section className={styles.portfolio_page_sec}>
            <div className="container sub-heading">
                <Tabs
                    id="uncontrolled-tab-example"
                    className={styles.portfolio_tbs}
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                >
                    {
                        PortfolioContentSecData.map((elem, i) => (
                            <Tab eventKey={elem.eventKey} title={elem.tabTitle}>
                                <div className="row justify-content-center">
                                    {
                                        elem.tabInnerImages.map((image, i) => (
                                            <div className="col-lg-4 col-md-6">
                                                <div className={styles.portfolio_page_itm}>
                                                    <img src={image.src} className="w-100" alt={image.alt} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Tab>
                        ))
                    }
                </Tabs>
            </div>
        </section>
    )
}

export default AboutContentSec