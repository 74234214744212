import React from 'react';
import styles from "./go-bottom.module.scss";
import { down_aarow } from "../../assets/images";

const GoBottom = ({scrollToElement}) => {
    function handleScroll() {
        // console.log(element, "element")
        // const elementPosition  = element.getBoundingClientRect().top;
        // const headerOffset = 0;
        // const offsetPosition = elementPosition - headerOffset;
        // window.scroll({
        //     top: offsetPosition,
        //     left: 0,
        //     behavior: 'smooth',
        // });

        if (scrollToElement && scrollToElement.current) {
            scrollToElement.current.scrollIntoView({ behavior: "smooth" });
        }

    }

    return (
        <>
            <div className={styles.arrow_btn}>
                <button type="button" onClick={handleScroll}><img src={down_aarow} alt="down Arrow" /></button>
            </div>
        </>
    )
}
export default GoBottom;