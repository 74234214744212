import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./about-page-about-sec.module.scss";
import { Service_page_Banner1 } from "../../assets/images";
// import { AiOutlineCheck } from "react-icons/ai";
import { GoArrowRight } from "react-icons/go";
// import CountUp from 'react-countup';
import { Link } from "react-router-dom";

const AboutSec = ({refToAboutSec}) => {
  return (
    <>
      <section ref= {refToAboutSec} className={styles.about_sec}>
        <Container className={styles.service_container}>
          <Row className="justify-content-center align-items-center">
            <Col lg="6">
              <img src={Service_page_Banner1} className={styles.about_sec_img} alt="banner" />
            </Col>
            <Col lg="6" className={styles.about_sec_rght}>
              <div className="sub-heading">
                <h2>Building Your Dream Home with  <span>Heart and Expertise</span></h2>
                <p>At <Link to="/">Denver Dream Builders</Link>, we prioritize your satisfaction, turning your architectural dreams into homes that are both beautiful and uniquely yours.</p>
                <ul>
                  <li><i><GoArrowRight /></i>Personalized Design </li>
                  <li><i><GoArrowRight /></i>Quality Craftsmanship</li>
                  <li><i><GoArrowRight /></i>Award-Winning Service</li>
                  <li><i><GoArrowRight /></i>Always At Your Service</li>
                </ul>
                <p>We excel in transforming your vision into reality, offering unmatched expertise and dedication. We commit wholeheartedly to excellence in building your dream home.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutSec;
