import Home from "../Views/Home/Home"
import Service from "../Views/Service"
import About from "../Views/About"
import Contact from "../Views/Contact"
import MainLayout from "../layout/MainLayout"
import NotFound from "../Views/NotFound"
import PrivacyPolicy from "../Views/PrivacyPolicy"
import Portfolio from "../Views/Portfolio"

const MainRoutes = {
    path: "",
    element: <MainLayout />,
    children: [
        {
            path: "/",
            element: <Home />
        },
        {
            path: "*",
            element: <NotFound />
        },
        {
            path: "/:name",
            element: <Service />
        },
        {
            path: "/about-us",
            element: <About />
        },
        {
            path: "/contact-us",
            element: <Contact />
        },
        {
            path: "/privacy-policy",
            element: <PrivacyPolicy />
        },
        {
            path: "/portfolio",
            element: <Portfolio />
        }
    ]
}


export default MainRoutes