import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./contact-page-banner.module.scss";
import GoBottom from "../Home/GoBottom";

const ContactBanner = ({ BannerData, scrollToElement }) => {
  return (
    <section className={styles.service_banner}>
      <Container className={styles.service_container}>
        <Row className="justify-content-center">
          <Col lg="10">
            <h1 className="text-center text-white text-uppercase mb-0">{BannerData.heading}</h1>
          </Col>
        </Row>
      </Container>
      <img src={BannerData.BannerImg} className={styles.service_banner_img} alt="banner data" />
      <GoBottom scrollToElement = {scrollToElement} />
    </section>
  );
};
export default ContactBanner;