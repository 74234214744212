import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styles from "../../assets/css/footer.module.scss";
import { Link, useLocation } from "react-router-dom";
import { logo_hmg } from "../../assets/images";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedinIn, FaPinterestP } from "react-icons/fa";
import ModelForm from '../../components/globalComponent/ModelPopup';

const Footer = () => {
    const { pathname } = useLocation();
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(false);
    }, [pathname]);
    const modelFormData = {
        btnName: "Book Now",
    }
    return (
        <>
            <footer className={styles.hmg_footer} >
                <Container>
                    <Row>
                        <Col lg="4" className={styles.hmg_footer_itm}>
                            <img src={logo_hmg} className="img-fluid" alt="footer logo" />
                            <p><a href="tel:+13035980433">+1 303 598 0433</a></p>
                            <p><a href="mailto:contact@denverdreambuilders.com">contact@denverdreambuilders.com</a></p>
                            <div className={styles.ftr_social_link}>
                                <Link to="https://www.facebook.com/denverdreambuilders" target="_blank">
                                    <i><FaFacebook /></i>
                                </Link>
                                <Link to="https://twitter.com/denver_builders" target="_blank">
                                    <i><FaTwitter /></i>
                                </Link>
                                <Link to="https://www.instagram.com/denverdreambuilders/" target="_blank">
                                    <i><FaInstagram /></i>
                                </Link>
                                <Link to="https://www.linkedin.com/company/denver-dream-builders/" target="_blank">
                                    <i><FaLinkedinIn /></i>
                                </Link>
                                <Link to="https://in.pinterest.com/denverdreambuilders/" target="_blank">
                                    <i><FaPinterestP /></i>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="5" className={styles.hmg_footer_itm}>
                            <div className={styles.hmg_footer_itm_middle}>
                                <h4>Quick Links</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/custom-home-builders">New Homes</Link>
                                    </li>
                                    <li>
                                        <Link to="/kitchen-remodeling-services">Kitchen Designing & Remodeling</Link>
                                    </li>
                                    <li>
                                        <Link to="/bathroom-remodeling">Bathroom Designing & Remodeling</Link>
                                    </li>
                                    <li>
                                        <Link to="/basement-remodeling">Basement Designing & Remodeling</Link>
                                    </li>
                                    <li>
                                        <Link to="/general-contractors">General Contracting</Link>
                                    </li>
                                    <li>
                                        <Link to="/floor-refinishing">Flooring</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="https://denverdreambuilders.com/blog/" target='_blank'>Blog</Link>
                                    </li>                                    
                                    <li>
                                        <Link to="/portfolio">Portfolio</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/privacy-policy">Privacy  Policy</Link>
                                    </li> */}
                                </ul>
                            </div>
                        </Col>
                        <Col lg="3" className={styles.hmg_footer_itm}>
                            <h4>Book a Consultation for Free!</h4>
                            <p>Explore our construction and remodeling services with a personalized consultation today</p>
                            <div className="footer-book-now-btn">
                                <ModelForm modelFormData={modelFormData} />
                            </div>
                        </Col>
                    </Row>
                    {/* <div className={`${styles.copy_right_text} d-flex justify-content-between flex-wrap`}>
                    <p className={`text-white mb-0 `}>© Copyright {new Date().getFullYear()} Denver Dream Builders. All Rights Reserved.</p>
                    <p className={`text-white mb-0 `}>Website by <Link to="https://scrumdigital.com/" target="_blank"><strong>Scrum Digital</strong></Link></p>
                    </div> */}
                    <div className={`copyright_footer ${styles.copy_right_text}`}>
                        <p className={`text-white mb-0`}>© Copyright {new Date().getFullYear()} Denver Dream Builders. All Rights Reserved Powered by <Link to="https://scrumdigital.com/" target="_blank"><strong>Scrum Digital</strong></Link>. </p>
                        <p className={`text-white mb-0`}><Link to="/privacy-policy">Privacy  Policy</Link></p>
                    </div>
                </Container>
            </footer>
        </>
    )
}
export default Footer